import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Menu as MenuIcon, ArrowRightAltOutlined } from '@mui/icons-material';
import FlexBetween from '../flexBetween';
import LogoutButton from '../LogoutButton';
import LoginButton from '../LoginButton';
import { useNavigate, generatePath } from 'react-router-dom';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  useTheme,
  TextField,
  Popper,
  MenuItem,
  ClickAwayListener,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import AlertSnackbar from '../AlertSnackbar';
import { useDispatch } from 'react-redux';
import { setBookingsFilters, setBookingsColumnVisibilityModel, setPermissions, setUserEmail } from '../../state/';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Booking } from 'models/booking.model';
import { sec } from 'auth/accessToken';
import axios from 'axios';
import AccountMenu from 'components/AccountMenu';

export interface NavbarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

export interface DecodedJwtPayload extends JwtPayload {
  permissions: string[];
}

const Navbar: React.FC<NavbarProps> = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getUserPreferences = async (userEmail: string) => {
      try {
        const accessToken = await sec.getAccessTokenSilently()();

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/userPreferences/${userEmail}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.data.bookingsColumnVisibilityModel) {
          dispatch(setBookingsColumnVisibilityModel(response.data.bookingsColumnVisibilityModel));
        }
        if (response.data.bookingsFilters) {
          dispatch(setBookingsFilters(response.data.bookingsFilters));
        }
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    const fetchToken = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          const decodedToken = jwtDecode<DecodedJwtPayload>(accessToken);
          dispatch(setPermissions(decodedToken.permissions));
          dispatch(setUserEmail(user?.email || ''));
          getUserPreferences(user?.email || '');
        } catch (error) {
          console.error('Error fetching token:', error);
        }
      }
    };

    fetchToken();
  }, [dispatch, isAuthenticated, getAccessTokenSilently, user]);

  const [search, setSearch] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [popperOpen, setPopperOpen] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]); // State to hold fetched bookings
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSearchButtonClick = async () => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/search/${search}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setBookings(response.data);
      setPopperOpen(true);
    } catch (error) {
      console.error('Error fetching bookings:', error);
      setSnackbarOpen(true);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSearchSelect = (booking: Booking) => {
    navigate(generatePath('/bookings/:id', { id: booking._id }));
    window.location.reload();
    setSearch('');
    setPopperOpen(false);
  };

  const handleClickAway = () => {
    setPopperOpen(false);
    setSearch('');
  };

  return (
    <AppBar
      sx={{
        position: 'static',
        background: 'none',
        boxShadow: 'none',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <FlexBetween gap={'1rem'}>
          <Box>
            <Tooltip title={`${isSidebarOpen ? 'Collapse' : 'Expand'}`}>
              <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                <MenuIcon
                  sx={{
                    color: theme.palette.secondary[200],
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          {isAuthenticated && (
            <>
              <TextField
                placeholder='Search Booking'
                autoComplete='off'
                value={search}
                onChange={handleSearchChange}
                variant='standard'
                inputRef={inputRef}
                sx={{
                  color: theme.palette.secondary[100],
                  fontSize: '1rem',
                  width: '15rem',
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleSearchButtonClick}>
                      <ArrowRightAltOutlined />
                    </IconButton>
                  ),
                }}
              />
              <Popper open={popperOpen} anchorEl={inputRef.current} placement='bottom-start'>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box>
                    {bookings.length === 0 ? (
                      <MenuItem
                        disabled
                        sx={{
                          bgcolor: theme.palette.background.alt,
                          fontSize: '0.8rem',
                          width: '15rem',
                        }}
                      >
                        No options
                      </MenuItem>
                    ) : (
                      bookings.map((booking: Booking) => (
                        <MenuItem
                          key={booking._id}
                          onClick={() => handleSearchSelect(booking)}
                          sx={{
                            bgcolor: theme.palette.background.alt,
                            fontSize: '0.8rem',
                            width: '15rem',
                            '&:hover': {
                              bgcolor: theme.palette.background.alt,
                              color: theme.palette.secondary[200],
                            },
                          }}
                        >
                          {booking.bookingNumber}
                        </MenuItem>
                      ))
                    )}
                  </Box>
                </ClickAwayListener>
              </Popper>
            </>
          )}
          <AlertSnackbar
            open={snackbarOpen}
            handleClose={handleSnackbarClose}
            severity={'error'}
            message={'Failed to fetch bookings. Please try again!'}
          />
        </FlexBetween>
        <FlexBetween gap='1.5rem' mr={'1rem'}>
          <AccountMenu user={user} />
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
