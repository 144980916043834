import { ClearOutlined } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Box, Button, Drawer, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MultiSelectAutoComplete from 'components/MultiSelectAutoComplete';
import { blStatuses, bookingStatuses, containerTypes } from 'dataAssets/constants';
import dayjs from 'dayjs';
import { TeamType } from 'models/booking.model';
import { VendorType } from 'models/index.model';
import { default as React, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { clearBookingsFilters, setBookingsFilters } from 'state';
import { useGetCustomersQuery, useGetPortsQuery, useGetVendorsQuery } from 'state/api';
import { generateMenuItemsFromArray } from 'utils/generateMenuItemsFromArray';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface SidebarProps {
  open: boolean;
  onClose: () => void;
  teams: TeamType[] | undefined;
}

const Sidebar: React.FC<SidebarProps> = ({ open, onClose, teams }) => {
  const dispatch = useDispatch();
  const bookingsFilters = useSelector((state: any) => state.global.bookingsFilters);
  const {
    startDate,
    endDate,
    bookingStatus,
    bookingTeam,
    consignor,
    consignee,
    carrier,
    portOfLoading,
    portOfDestination,
    containerType,
    salesInvoiceCreated,
    purchaseInvoiceCreated,
    blStatus,
    outstandingAdditionalCharges,
  } = bookingsFilters;

  const { data: customers } = useGetCustomersQuery();
  const { data: vendors } = useGetVendorsQuery();
  const { data: ports } = useGetPortsQuery();

  const defaultFilterValues = {
    // since OnGoing bookingSttaus is pushed by default, safe to put it in array before we sent to multiselect comp
    status: Array.isArray(bookingStatus) ? [...bookingStatus] : [bookingStatus],
    consignors: consignor || [],
    consignees: consignee || [],
    carriers: carrier || [],
    portOfLoadings: portOfLoading || [],
    portOfDestinations: portOfDestination || [],
    containerTypes: containerType || [],
    blStatuses: blStatus || [],
  };

  const { control, setValue, reset } = useForm({
    defaultValues: defaultFilterValues,
  });

  useEffect(() => {
    reset(defaultFilterValues);
  }, [bookingsFilters, reset]);

  const handleStartDateChange = (newDate: dayjs.Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      dispatch(setBookingsFilters({ startDate: newDate.toISOString() }));
    }
  };
  const handleEndDateChange = (newDate: dayjs.Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      dispatch(setBookingsFilters({ endDate: newDate.toISOString() }));
    }
  };

  const handleBookingTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setBookingsFilters({ bookingTeam: e.target.value }));
  };

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box display='flex' flexDirection='column' gap='1rem' p='1rem' width='300px'>
        <MultiSelectAutoComplete
          control={control}
          name='status'
          label='Status'
          options={bookingStatuses || []}
          onChange={value => {
            setValue('status', value);
            dispatch(setBookingsFilters({ bookingStatus: value }));
          }}
        />

        <TextField
          select
          label='Team'
          value={bookingTeam}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBookingTeamChange(e)}
        >
          {generateMenuItemsFromArray(teams ?? [])}
        </TextField>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='Creation Start'
            format='DD/MM/YYYY'
            value={dayjs(startDate)}
            onChange={handleStartDateChange}
          />
          <DatePicker label='Creation End' format='DD/MM/YYYY' value={dayjs(endDate)} onChange={handleEndDateChange} />
        </LocalizationProvider>
        {/* consignor */}
        <MultiSelectAutoComplete
          control={control}
          name='consignors'
          label='Consignors'
          options={customers || []}
          onChange={value => {
            setValue('consignors', value);
            dispatch(setBookingsFilters({ consignor: value }));
          }}
        />
        {/* consignee */}
        <MultiSelectAutoComplete
          control={control}
          name='consignees'
          label='Consignee'
          options={customers || []}
          onChange={value => {
            setValue('consignees', value);
            dispatch(setBookingsFilters({ consignee: value }));
          }}
        />
        {/* carrier */}
        <MultiSelectAutoComplete
          control={control}
          name='carriers'
          label='Carrier'
          options={(vendors || []).filter(vendor => vendor.vendorType === VendorType.Carrier)}
          onChange={value => {
            setValue('carriers', value);
            dispatch(setBookingsFilters({ carrier: value }));
          }}
        />
        {/* port of loading */}
        <MultiSelectAutoComplete
          control={control}
          name='portOfLoadings'
          label='POL'
          options={ports || []}
          onChange={value => {
            setValue('portOfLoadings', value);
            dispatch(setBookingsFilters({ portOfLoading: value }));
          }}
        />
        {/* port of destination */}
        <MultiSelectAutoComplete
          control={control}
          name='portOfDestinations'
          label='POD'
          options={ports || []}
          onChange={value => {
            setValue('portOfDestinations', value);
            dispatch(setBookingsFilters({ portOfDestination: value }));
          }}
        />
        {/* container type */}
        <MultiSelectAutoComplete
          control={control}
          name='containerTypes'
          label='Container Type'
          options={containerTypes || []}
          onChange={value => {
            setValue('containerTypes', value);
            dispatch(setBookingsFilters({ containerType: value }));
          }}
        />
        {/* bl status */}
        <MultiSelectAutoComplete
          control={control}
          name='blStatuses'
          label='BL Status'
          options={blStatuses || []}
          onChange={value => {
            setValue('blStatuses', value);
            dispatch(setBookingsFilters({ blStatus: value }));
          }}
        />

        <Autocomplete
          value={salesInvoiceCreated ? salesInvoiceCreated : null}
          options={['Yes', 'No']}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(setBookingsFilters({ salesInvoiceCreated: value }));
            } else {
              dispatch(setBookingsFilters({ salesInvoiceCreated: undefined }));
            }
          }}
          renderInput={params => <TextField {...params} label='Sales Invoice Created' />}
        />
        <Autocomplete
          value={purchaseInvoiceCreated ? purchaseInvoiceCreated : null}
          options={['Yes', 'No']}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(setBookingsFilters({ purchaseInvoiceCreated: value }));
            } else {
              dispatch(setBookingsFilters({ purchaseInvoiceCreated: undefined }));
            }
          }}
          renderInput={params => <TextField {...params} label='Purchase Invoice Created' />}
        />
        <Autocomplete
          value={outstandingAdditionalCharges ? outstandingAdditionalCharges : null}
          options={['Yes', 'No']}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(_, value) => {
            if (value) {
              dispatch(setBookingsFilters({ outstandingAdditionalCharges: value }));
            } else {
              dispatch(setBookingsFilters({ outstandingAdditionalCharges: undefined }));
            }
          }}
          renderInput={params => <TextField {...params} label='Outstanding Additional Charges' />}
        />
        <Button
          color='error'
          startIcon={<ClearOutlined />}
          onClick={() => {
            dispatch(clearBookingsFilters());
          }}
        >
          Clear Filters
        </Button>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
