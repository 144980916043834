import React from 'react';
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';
import FlexBetween from '../flexBetween';

export interface DataGridCustomToolbarProps {
  searchInput: string;
  setSearchInput: Function;
  setSearch: Function;
}
const DataGridCustomToolbar: React.FC<DataGridCustomToolbarProps> = ({ searchInput, setSearchInput, setSearch }) => {
  return (
    <GridToolbarContainer sx={{ m: '1rem 0 0.8rem 0' }}>
      <FlexBetween width='100%'>
        <FlexBetween>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </FlexBetween>
        {/* IF USING SERVER SIDE PAGINATION */}
        {/* <TextField
          label="Search..."
          sx={{ mb: "0.5rem", width: "15rem" }}
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearch(searchInput);
                    setSearchInput("");
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}
      </FlexBetween>
    </GridToolbarContainer>
  );
};

export default DataGridCustomToolbar;
