export interface Contact {
  _id: string;
  name: string;
  email: string;
  phoneNumber?: string;
  role: string;
}
export interface EmailConfig {
  name: string;
  from: string[];
  to: string[];
  cc: string[];
}

interface DBUser {
  _id: string;
  name: string;
  address: string;
  city: string;
  postcode: string;
  country: string;
  creditPeriod: number;
  creditLimit: number;
  contacts: Contact[];
  emailConfig?: EmailConfig[];
}

export interface Vendor extends DBUser {
  vendorType: VendorType;
}

export interface UserPreference {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
  phoneNumber: number;
  signature: string;
  bookingsColumnVisibilityModel: Record<string, boolean>;
  bookingsFilters: Record<string, string>;
}

export interface Customer extends DBUser {
  EORI?: string;
}
export interface Site {
  _id: string;
  address: string;
  city: string;
  postcode: string;
  country: string;
  siteName?: string;
}

export interface Token {
  _id: string;
  refreshToken: string;
  accessToken: string;
  tokenType?: string;
  expiresIn: number;
}

export interface Oauth {
  _id: string;
  provider: string;
  clientId: string;
  clientSecret: string;
  redirectUrl: string;
  scopes: string[];
  state?: string;
  authUrl: string;
  tokenUrl: string;
  tokens: Token[];
}

export interface Port {
  _id: string;
  portName: string;
  country: string;
  portCode: string;
}

export interface BaseDialogueProps {
  handleClose: Function;
  open: boolean;
}

export type ValueAndLabel<T> = {
  value: T;
  label: string;
};

export enum VendorType {
  Carrier = 'Carrier',
  Haulier = 'Haulier',
  Agent = 'Agent',
}
export interface CountryType {
  id: number;
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

export interface ChargeName {
  _id?: string;
  chargeName: string;
  chargeType: string;
  defaultBase: string;
}
