import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller, useWatch, UseFormReturn, FieldValues } from 'react-hook-form';
import { Invoice, Booking } from 'models/booking.model';
import { ChargeName } from 'models/index.model';

interface BaseIsolatedReRenderProps<T extends FieldValues> {
  index: number;
  form: UseFormReturn<T, any, T>;
  bases: string[];
  formPath: string;
}

export const BaseIsolatedReRender: React.FC<BaseIsolatedReRenderProps<Invoice | Booking>> = ({
  index,
  form,
  bases,
  formPath,
}) => {
  // Watch the chargeName field and cast it to ChargeName or null safely
  const chargeName = useWatch({
    control: form.control,
    name: `${formPath}.${index}.chargeName` as keyof (Invoice | Booking),
    defaultValue: form.getValues(`${formPath}.${index}.chargeName` as keyof (Invoice | Booking)),
  }) as unknown as ChargeName | null;

  const baseFieldState = form.getFieldState(`${formPath}.${index}.base` as keyof (Invoice | Booking));

  useEffect(() => {
    if (!baseFieldState.isDirty && chargeName && typeof chargeName === 'object') {
      const defaultBase = chargeName.defaultBase || bases[0];
      form.setValue(`${formPath}.${index}.base` as keyof (Invoice | Booking), defaultBase);
    }
  }, [chargeName, baseFieldState.isDirty, bases, form, index, formPath]);

  return (
    <Controller
      name={`${formPath}.${index}.base` as keyof (Invoice | Booking)}
      control={form.control}
      defaultValue={bases[0]}
      render={({ field: { value, onChange } }) => (
        <Autocomplete
          freeSolo
          autoSelect
          value={value}
          onChange={(_, value) => onChange(value)}
          options={bases}
          renderInput={params => <TextField {...params} label='Base *' />}
        />
      )}
    />
  );
};
