import { ColorPartial } from '@mui/material/styles/createPalette';

const fontFamily = 'Rubik, sans-serif';
// color design tokens export
export const tokensDark: Record<string, ColorPartial> = {
  grey: {
    0: '#ffffff', // manually adjusted
    10: '#f6f6f6', // manually adjusted
    50: '#f0f0f0', // manually adjusted
    100: '#e0e0e0',
    200: '#c2c2c2',
    300: '#a3a3a3',
    400: '#858585',
    500: '#666666',
    600: '#525252',
    700: '#3d3d3d',
    800: '#292929',
    900: '#141414',
    1000: '#202124',
    1100: '#000000', // manually adjusted
  },
  primary: {
    // blue
    100: '#d3d4de',
    200: '#a6a9be',
    300: '#7a7f9d',
    400: '#4d547d',
    500: '#21295c',
    600: '#191F45', // manually adjusted
    700: '#141937',
    800: '#0d1025',
    900: '#070812',
  },
  secondary: {
    100: '#ccdceb',
    200: '#99b9d6',
    300: '#6696c2',
    400: '#3373ad',
    500: '#005099',
    600: '#00407a',
    700: '#00305c',
    800: '#00203d',
    900: '#00101f',
  },
  // secondary: {
  //   // yellow
  //   50: "#f0f0f0", // manually adjusted
  //   100: "#fff6e0",
  //   200: "#ffedc2",
  //   300: "#ffe3a3",
  //   400: "#ffda85",
  //   500: "#ffd166",
  //   600: "#cca752",
  //   700: "#997d3d",
  //   800: "#665429",
  //   900: "#332a14",
  // },
};

// function that reverses the color palette
function reverseTokens(tokensDark: Record<string, ColorPartial>) {
  const reversedTokens: Record<string, ColorPartial> = {};
  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj: any = {}; //todo: fix typing
    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }
    reversedTokens[key] = reversedObj;
  });
  return reversedTokens;
}
export const tokensLight = reverseTokens(tokensDark);

// mui theme settings
// todo: type this wiht PaletteOptions (need to adjust type first though)
export const themeSettings = (mode: string) => {
  return {
    palette: {
      mode: mode,
      ...(mode === 'dark'
        ? {
            // palette values for dark mode
            primary: {
              ...tokensDark.primary,
              main: tokensDark.primary[400],
              light: tokensDark.primary[400],
            },
            secondary: {
              ...tokensDark.secondary,
              main: tokensDark.secondary[300],
            },
            neutral: {
              ...tokensDark.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.grey[1000],
              alt: tokensDark.grey[800],
            },
          }
        : {
            // palette values for light mode
            primary: {
              ...tokensLight.primary,
              main: tokensDark.grey[50],
              light: tokensDark.grey[100],
            },
            secondary: {
              ...tokensLight.secondary,
              main: tokensDark.secondary[600],
              light: tokensDark.secondary[700],
            },
            neutral: {
              ...tokensLight.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.grey[0],
              alt: tokensDark.grey[50],
            },
          }),
    },
    typography: {
      fontFamily,
      fontSize: 12,
      h1: {
        fontFamily,
        fontSize: 40,
      },
      h2: {
        fontFamily,
        fontSize: 32,
      },
      h3: {
        fontFamily,
        fontSize: 24,
      },
      h4: {
        fontFamily,
        fontSize: 20,
      },
      h5: {
        fontFamily,
        fontSize: 16,
      },
      h6: {
        fontFamily,
        fontSize: 14,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1700,
      },
    },
  };
};
