import { configureStore } from '@reduxjs/toolkit';

import globalReducer from '../state';
import { api } from '../state/api';

export const reduxStore = configureStore({
  reducer: {
    global: globalReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: getDefault => getDefault().concat(api.middleware),
});
