import React, { useReducer, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { AddOutlined } from '@mui/icons-material';
import Header from '../../components/Header';
import { useGetAuditsQuery, useGetContactsQuery } from '../../state/api';
import { Box, Button, useTheme } from '@mui/material';
import { Contact } from 'models/index.model';
import { initialState, reducer } from '../../utils/dialogState';
import { useUserPermissions } from 'utils/utils';
import { generateActionsColumn } from 'utils/generateActionsColumn';
import axios from 'axios';
import { sec } from 'auth/accessToken';
import CustomDataGrid from 'components/CustomDataGrid';
import AuditLogsDialogue from 'components/AuditLogsDialogue';
import CreateContactDialogue from 'components/CreateContactDialogue';

const Contacts = () => {
  const theme = useTheme();

  const userPermissions = useUserPermissions();
  const hasCreateMasterDataPermission = userPermissions.includes('create:masterdata');
  const hasUpdateMasterDataPermission = userPermissions.includes('update:masterdata');
  const hasDeleteMasterDataPermission = userPermissions.includes('delete:masterdata');

  const [{ open, entityToUpdate }, dispatch] = useReducer(reducer<Contact>, initialState<Contact>());

  const handleClickOpenCreate = () => {
    dispatch({ type: 'OPEN_DIALOG', payload: {} as Contact });
  };

  const handleClickOpenEdit = (contact: Contact) => {
    dispatch({ type: 'OPEN_DIALOG', payload: contact });
  };

  const handleClose = () => {
    dispatch({ type: 'CLOSE_DIALOG' });
    refetch();
  };

  const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false);
  const [openAuditDialogue, setOpenAuditDialogue] = useState(false);
  const [currentMasterDataId, setCurrentMasterDataId] = useState<string>('');

  const { data: allContacts, isLoading, refetch } = useGetContactsQuery();
  const { data: auditLogs } = useGetAuditsQuery(['Contact', currentMasterDataId]);

  const deleteContact = async (contactId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/contacts/${contactId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.6,
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.3,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 0.3,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.4,
    },
  ];

  if (hasUpdateMasterDataPermission || hasDeleteMasterDataPermission) {
    columns.push(
      generateActionsColumn<Contact>(
        hasUpdateMasterDataPermission,
        hasDeleteMasterDataPermission,
        handleClickOpenEdit,
        setOpenAuditDialogue,
        deleteContact,
        openConfirmDialogue,
        setOpenConfirmDialogue,
        currentMasterDataId,
        setCurrentMasterDataId,
      ),
    );
  }

  return (
    <Box m='1.5rem 2.5rem'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Header title='Contacts' />
        <Box>
          {hasCreateMasterDataPermission && (
            <Button
              variant='contained'
              startIcon={<AddOutlined />}
              onClick={handleClickOpenCreate}
              sx={{
                bgcolor: theme.palette.secondary[400],
                '&:hover': {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
            >
              Create Contact
            </Button>
          )}
          <CreateContactDialogue handleClose={handleClose} open={open} contactToUpdate={entityToUpdate} />
        </Box>
      </Box>
      <CustomDataGrid
        data={allContacts}
        columns={columns}
        isLoading={isLoading}
        sortModel={[{ field: 'name', sort: 'asc' }]}
      />
      <AuditLogsDialogue
        open={openAuditDialogue}
        handleClose={() => setOpenAuditDialogue(false)}
        auditLogs={auditLogs}
      />
    </Box>
  );
};

export default Contacts;
