import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';

export interface HeaderProps {
  title: string;
  subtitle?: string;
}

const Header: React.FC<HeaderProps> = ({ title, subtitle }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant='h1' color={theme.palette.secondary[300]} fontWeight='bold' sx={{ mb: '5px' }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant='h5' color={theme.palette.secondary[300]}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default Header;
