import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { BookingStatus, TeamType } from 'models/booking.model';

const initialState = {
  mode: 'dark',
  userEmail: '',
  permissions: [],
  bookingsColumnVisibilityModel: {
    bookingNumber: true,
    consignor: true,
    consignee: false,
    notifyParty: false,
    carrier: true,
    carrierBookingNumber: true,
    carrierQuotationReference: false,
    purchaseOrderNumber: true,
    mblNumber: false,
    hblNumber: false,
    blRemarks: true,
    blStatus: true,
    blStatusDate: true,
    followUp: true,
    followUpDate: true,
    agent: false,
    haulers: false,
    sites: true,
    portOfLoading: true,
    portOfDestination: true,
    placeOfDelivery: false,
    etd: false,
    eta: false,
    etaPOL: false,
    quotationApproval: false,
    numberOfContainers: true,
    packages: false,
    approximateWeight: false,
    containerType: false,
    doorFacing: false,
    bookingType: false,
    releasePin: false,
    loadingTypeExport: false,
    loadingTypeImport: false,
    cargoDescription: false,
    HSCode: false,
    cargoValue: false,
    vesselVoyage: false,
    dateCreated: true,
    bookingTeam: false,
    actions: true,
  } as GridColumnVisibilityModel,
  bookingsFilters: {
    startDate: dayjs().subtract(30, 'day').toISOString(),
    endDate: dayjs().toISOString(),
    bookingStatus: BookingStatus.Ongoing,
    bookingTeam: TeamType.All,
    consignor: undefined,
    consignee: undefined,
    carrier: undefined,
    portOfLoading: undefined,
    portOfDestination: undefined,
    containerType: undefined,
    salesInvoiceCreated: undefined,
    purchaseInvoiceCreated: undefined,
    blStatus: undefined,
    outstandingAdditionalCharges: undefined,
  },
  invoicesFilters: {
    invoiceType: 'salesInvoices',
    pendingApproval: true,
    invoiceOutstanding: true,
    underDispute: false,
    startDate: dayjs().subtract(30, 'day').toISOString(),
    endDate: dayjs().toISOString(),
    dateType: 'invoiceDate',
    bookingTeam: TeamType.All,
  },
  loadingsFilters: {
    startDate: dayjs().toISOString(),
    endDate: dayjs().toISOString(),
    bookingTeam: TeamType.All,
  },
  deadlinesFilters: {
    startDate: dayjs().toISOString(),
    endDate: dayjs().toISOString(),
    bookingTeam: TeamType.All,
  },
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setMode: state => {
      state.mode = state.mode === 'light' ? 'dark' : 'light';
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setBookingsColumnVisibilityModel: (state, action) => {
      state.bookingsColumnVisibilityModel = action.payload;
    },
    setBookingsFilters: (state, action) => {
      state.bookingsFilters = { ...state.bookingsFilters, ...action.payload };
    },
    clearBookingsFilters: state => {
      state.bookingsFilters = initialState.bookingsFilters;
    },
    setInvoicesFilters: (state, action) => {
      state.invoicesFilters = { ...state.invoicesFilters, ...action.payload };
    },
    setLoadingsFilters: (state, action) => {
      state.loadingsFilters = { ...state.loadingsFilters, ...action.payload };
    },
    setDeadlinesFilters: (state, action) => {
      state.deadlinesFilters = { ...state.deadlinesFilters, ...action.payload };
    },
  },
});

export const {
  setMode,
  setUserEmail,
  setPermissions,
  setBookingsColumnVisibilityModel,
  setBookingsFilters,
  clearBookingsFilters,
  setInvoicesFilters,
  setLoadingsFilters,
  setDeadlinesFilters,
} = globalSlice.actions;

export default globalSlice.reducer;
