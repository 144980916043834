import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import AlertSnackbar from 'components/AlertSnackbar';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import dayjs from 'dayjs';
import { Booking, Invoice, InvoiceCharge } from 'models/booking.model';
import { BaseDialogueProps, Customer, Vendor } from 'models/index.model';
import React, { useEffect, useState } from 'react';
import { Controller, UseFormReturn, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useGetAuditsQuery, useGetCustomersQuery, useGetVendorsQuery } from 'state/api';
import { ExRateIsolatedReRender } from 'utils/renderExRate';
import { BaseIsolatedReRender } from 'utils/renderBase';
import { getValueAndLabelFromCustomer, getValueAndLabelFromVendor, totalInvoiceValue } from 'utils/utils';
import { sec } from '../../auth/accessToken';
import { bases, currencies, invoiceTypes } from '../../dataAssets/constants';
import { generateMenuItemsFromArray } from '../../utils/generateMenuItemsFromArray';
import ChargeNameAutocomplete from 'components/ChargeNameAutocomplete';

export interface InvoiceDialogueProps extends BaseDialogueProps {
  id: string;
  invoices: Invoice[];
  booking: Booking;
  invoiceType: string;
  currentInvoice?: Invoice;
}

const InvoiceDialogue: React.FC<InvoiceDialogueProps> = ({
  handleClose,
  open,
  id,
  invoices,
  booking,
  invoiceType,
  currentInvoice,
}) => {
  const theme = useTheme();

  const [sendToVendor, setSendToVendor] = useState(false);
  const [sendToAnotherCustomer, setSendToAnotherCustomer] = useState(false);
  const [underDispute, setUnderDispute] = useState(false);
  const [createHmrcDn, setCreateHmrcDn] = useState(false);

  const { data: vendors } = useGetVendorsQuery();
  const { data: customers } = useGetCustomersQuery();
  const { refetch: refetchAuditLogs } = useGetAuditsQuery(['Booking', id]);

  const currentForm = useForm<Invoice>({
    defaultValues: {
      charges: [{} as InvoiceCharge],
      numberOfContainers: booking.numberOfContainers,
    },
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = currentForm;

  const {
    fields: chargesFields,
    append: chargesAppend,
    remove: chargesRemove,
  } = useFieldArray({
    control,
    name: 'charges',
  });

  useEffect(() => {
    reset(currentInvoice);
    setSendToVendor(false);
    setSendToAnotherCustomer(false);
    setUnderDispute(false);
    setCreateHmrcDn(false);
  }, [open, reset, currentInvoice]);

  useEffect(() => {
    if (currentInvoice) {
      setValue('charges', currentInvoice.charges);
      setValue('remarks', currentInvoice.remarks);
      setValue('invoiceCurrency', currentInvoice.invoiceCurrency);
      setValue('vendorInvoiceNumber', currentInvoice.vendorInvoiceNumber);
      setValue('vendor', currentInvoice.vendor);
      setValue('vendorInvoiceDate', dayjs(currentInvoice.vendorInvoiceDate).format());
      setValue('customer', currentInvoice.customer);
    }
  }, [currentInvoice, setValue]);

  const handleVendorChange = (value: { label: string; value: string } | null) => {
    if (!value) return;
    const vendor = vendors?.find(item => item._id === value?.value);
    if (vendor) {
      setValue('vendor' as keyof Invoice, vendor);
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const invoicesIds = invoices.map(item => item._id);

  const onSubmit = async (data: Invoice) => {
    try {
      const newData = {
        ...data,
        bookingNumber: booking.bookingNumber,
        customer:
          data.customer ||
          (data.vendor === undefined
            ? booking.bookingType === 'Export'
              ? booking.consignor
              : booking.consignee
            : undefined),
      };
      const accessToken = await sec.getAccessTokenSilently()();

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${invoiceType}/${createHmrcDn ? `?createHmrcDn=${true}` : ''}`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      invoicesIds.push(response.data.id);
      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${id}`,
        { [invoiceType]: invoicesIds },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      handleClose();
      setSnackbarOpen(true);
      refetchAuditLogs();
    } catch (error) {
      console.error(error);
    }
  };

  const [totalValue, setTotalValue] = useState(0);

  const watchedRates = useWatch({
    control,
    name: 'charges',
  });

  const watchedNumberOfContainers = useWatch({
    control,
    name: 'numberOfContainers',
  });

  useEffect(() => {
    const total = totalInvoiceValue(watchedRates, watchedNumberOfContainers);
    setTotalValue(parseFloat(total));
  }, [watchedRates, watchedNumberOfContainers]);

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='xl'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          Create {invoiceTypes[invoiceType]}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Invoices will be automatically be addressed to the customer of the booking unless vendor is selected.
          </Typography>
          <form>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={'0.5px solid rgba(255, 255, 255, 0.12)'}
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant='h5'>Charges</Typography>
                <Button
                  variant='contained'
                  type='button'
                  onClick={() => chargesAppend({} as InvoiceCharge)}
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box m={'1rem 0 0 0 0'}>
                {chargesFields.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'35fr 35fr 8fr 10fr 8fr 8fr 3fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id}
                    >
                      <ChargeNameAutocomplete
                        control={control}
                        name={`charges.${index}.chargeName`}
                        chargeType={
                          invoiceType === 'salesInvoices' || invoiceType === 'debitNotes' ? 'Sales' : 'Purchase'
                        }
                      />
                      <TextField
                        label='Additional Details'
                        autoComplete='off'
                        {...register(`charges.${index}.additionalDetails`)}
                      />
                      <Controller
                        name={`charges.${index}.currency`}
                        control={control}
                        defaultValue={currencies[0]}
                        render={({ field }) => (
                          <TextField {...field} select autoComplete='off' label='Currency *'>
                            {generateMenuItemsFromArray(currencies)}
                          </TextField>
                        )}
                      />
                      <ExRateIsolatedReRender form={currentForm} index={index} defaultCurrency={currencies[0]} />
                      <TextField
                        autoComplete='off'
                        type='number'
                        label='Value *'
                        {...register(`charges.${index}.rate`, {
                          required: 'Value is required',
                        })}
                        error={!!errors?.charges?.[index]?.rate}
                        helperText={errors?.charges?.[index]?.rate?.message}
                      />
                      <BaseIsolatedReRender
                        form={currentForm as UseFormReturn<Invoice | Booking, any, Invoice | Booking>}
                        index={index}
                        bases={bases}
                        formPath='charges'
                      />
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => chargesRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
                <Divider sx={{ marginTop: '1rem' }} />
                <Box mt={'1rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <TextField
                    autoComplete='off'
                    size='small'
                    type='number'
                    label='Containers *'
                    {...register('numberOfContainers', {
                      required: 'Containers is required',
                    })}
                    error={!!errors.numberOfContainers}
                    helperText={errors.numberOfContainers?.message}
                  />
                  <Typography variant='h6'>Total Invoice Value: {totalValue}</Typography>
                </Box>
              </Box>
            </Box>
            <Box display={'grid'} gridTemplateColumns={'8fr 1fr'} gap={'1rem'}>
              <TextField label='Remarks' autoComplete='off' {...register('remarks')} />
              <Controller
                name={`invoiceCurrency`}
                control={control}
                defaultValue={currencies[0]}
                render={({ field }) => (
                  <TextField {...field} select autoComplete='off' label='Invoice Currency *'>
                    {generateMenuItemsFromArray(currencies)}
                  </TextField>
                )}
              />
            </Box>
            {invoiceType !== 'purchaseInvoices' && (
              <Box mt={'1rem'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendToVendor}
                      onChange={() => setSendToVendor(!sendToVendor)}
                      disabled={sendToAnotherCustomer}
                    />
                  }
                  label='Address to Vendor'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendToAnotherCustomer}
                      onChange={() => setSendToAnotherCustomer(!sendToAnotherCustomer)}
                      disabled={sendToVendor}
                    />
                  }
                  label='Address to Another Customer'
                />
              </Box>
            )}
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'} gap={'1rem'} mt={'1rem'}>
              {(invoiceType === 'purchaseInvoices' || sendToVendor) && (
                <Controller
                  control={control}
                  name={'vendor'}
                  rules={{ required: 'Vendor is required' }}
                  render={({ field: { value } }) => (
                    <Autocomplete
                      value={
                        value === undefined
                          ? currentInvoice?.vendor
                            ? getValueAndLabelFromVendor(currentInvoice.vendor)
                            : null
                          : getValueAndLabelFromVendor(value)
                      }
                      onChange={(event, value) => {
                        handleVendorChange(value);
                      }}
                      options={(vendors ?? []).map((vendor: Vendor) => getValueAndLabelFromVendor(vendor))}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Vendor *'
                          error={!!errors.vendor}
                          helperText={errors.vendor?.message}
                        />
                      )}
                    />
                  )}
                />
              )}
              {sendToAnotherCustomer && (
                <Controller
                  control={control}
                  name={'customer'}
                  rules={{ required: 'Customer is required' }}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      disablePortal
                      options={(customers ?? []).map((customer: Customer) => getValueAndLabelFromCustomer(customer))}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      onChange={(_, value) => {
                        onChange(value?.value || '');
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Customer *'
                          error={!!errors.customer}
                          helperText={errors.customer?.message}
                        />
                      )}
                    />
                  )}
                />
              )}
              {invoiceType === 'purchaseInvoices' && (
                <>
                  <TextField
                    label='Vendor Invoice No *'
                    autoComplete='off'
                    {...register('vendorInvoiceNumber', {
                      required: 'Vendor Invoice No is required',
                    })}
                    error={!!errors.vendorInvoiceNumber}
                    helperText={errors.vendorInvoiceNumber?.message}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name='vendorInvoiceDate'
                      rules={{ required: 'Vendor Invoice Date is required' }}
                      render={({ field: { onChange, value, ref } }) => (
                        <DatePicker
                          label='Vendor Invoice Date *'
                          format='DD/MM/YYYY'
                          value={value ? dayjs(value) : null}
                          inputRef={ref}
                          onChange={date => onChange(dayjs(date).format())}
                          slotProps={{
                            textField: {
                              error: !!errors.vendorInvoiceDate,
                              helperText: errors.vendorInvoiceDate?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </>
              )}
            </Box>
            <Box mt={'1rem'}>
              <Controller
                control={control}
                name={'disputeDetails.underDispute'}
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        onChange={e => {
                          onChange(e.target.checked);
                          setUnderDispute(e.target.checked);
                        }}
                      />
                    }
                    label='Under Dispute'
                  />
                )}
              />
              {invoiceType === 'purchaseInvoices' && (
                <FormControlLabel
                  control={<Checkbox checked={createHmrcDn} onChange={() => setCreateHmrcDn(!createHmrcDn)} />}
                  label='Create HMRC DN'
                />
              )}
            </Box>
            {underDispute && (
              <Box mt={'1rem'} display={'grid'} gridTemplateColumns={'repeat(3,1fr)'} gap={'1rem'}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    name='disputeDetails.disputeDate'
                    render={({ field: { onChange, value, ref } }) => (
                      <DatePicker
                        label='Dispute Date'
                        format='DD/MM/YYYY'
                        value={value ? dayjs(value) : null}
                        inputRef={ref}
                        onChange={date => onChange(dayjs(date).format())}
                      />
                    )}
                  />
                </LocalizationProvider>
                <TextField label='Dispute Remarks' autoComplete='off' {...register('disputeDetails.disputeRemarks')} />
                <TextField
                  label='Dispute Follow Up'
                  autoComplete='off'
                  {...register('disputeDetails.disputeFollowUp')}
                />
              </Box>
            )}
          </form>
        </DialogContent>
        <GeneralDialogueActions onClick={handleSubmit(onSubmit)} handleClose={handleClose} submitText='Create' />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={'success'}
        message={`${invoiceTypes[invoiceType]} Created !`}
      />
    </Box>
  );
};

export default InvoiceDialogue;
