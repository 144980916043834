import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetAuditsQuery,
  useGetBookingProfitQuery,
  useGetBookingQuery,
  useGetCustomerOutstandingAmountQuery,
} from '../../state/api';
import { useGetFilesQuery } from '../../state/api';
import {
  Box,
  Typography,
  useTheme,
  CircularProgress,
  IconButton,
  Divider,
  Alert,
  AlertTitle,
  Tooltip,
  Tabs,
  Tab,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import dayjs from 'dayjs';
import VerticalStepper from '../../components/VerticalStepper';
import QuotationTable from '../../components/QuotationTable';
import InvoiceTable from '../../components/InvoiceTable';
import TransportScheduleTable from '../../components/TransportScheduleTable';
import UpdateBookingDetails from '../../components/UpdateBookingDialogue';
import UpdateTransportScheduleDialogue from '../../components/UpdateTransportScheduleDialogue';
import DeadlinesDialogue from '../../components/DeadlinesDialogue';
import DeadlineCalendar from '../../components/DeadlineCalendar';
import ChipTitle from '../../components/ChipTitle';
import DeadlineAlert from '../../components/DeadlineAlert';
import MultipleFileUploadComponent from '../../components/MultipleFileUploadComponent';
import DocumentsTable from '../../components/DocumentsTable';
import updateLocale from 'dayjs/plugin/updateLocale';
import { BookingStatus, DeadlineType, StepStatus, TransportStatus } from 'models/booking.model';
import { invoiceTypes, documentTypes } from 'dataAssets/constants';
import SplitBookingDialogue from 'components/SplitBookingDialogue';
import BookingDetails from 'components/BookingDetails';
import BookingSpeedDial from 'components/BookingSpeedDial';
import InvoiceReportDialogue from 'components/InvoiceReportDialogue';
import ProfitLossDialogue from 'components/ProfitLossDialogue';
import MergeBookingDialogue from 'components/MergeBookingDialogue';
import AuditLogsDialogue from 'components/AuditLogsDialogue';
import { extractDeadlineTime, setDialogueState } from 'utils/utils';
import UpdateBLHistoryDialogue from 'components/UpdateBLHistoryDialogue';
import BLHistoryTable from 'components/BLHistoryTable/BLHistoryTable';
import AdditionalChargesTable from 'components/AdditionalChargesTable';
import { RefreshOutlined } from '@mui/icons-material';
import { exportSteps } from 'dataAssets/actionSteps';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const Booking = () => {
  const theme = useTheme();
  const { id } = useParams() as { id: string };

  const { data: booking, isLoading, refetch } = useGetBookingQuery(id);
  const {
    data: currentBookingIncome,
    isLoading: bookingIncomeLoading,
    refetch: bookingIncomeRefetch,
  } = useGetBookingProfitQuery(id);

  const customer = booking?.bookingType === 'Export' ? booking?.consignor : booking?.consignee;
  const { data: customerOutstandingAmount } = useGetCustomerOutstandingAmountQuery(customer?._id ?? '', {
    skip: !customer,
  });
  const hasCustomerCreditLimitExceeded =
    Number(customer?.creditLimit) !== 0 && Number(customerOutstandingAmount) > Number(customer?.creditLimit);

  const { data: files, refetch: filesRefetch } = useGetFilesQuery(booking?.bookingNumber);

  const { data: bookingsAuditLogs } = useGetAuditsQuery(['Booking', id]);

  const invoicesRefetch = () => {
    refetch();
    bookingIncomeRefetch();
  };

  const uplodedFileTypes = files?.map((file: any) => file.Key.split('/')[1]);

  const currentDate = dayjs().format('DD-MM-YYYY');

  const bookingCompleted = booking?.bookingStatus === BookingStatus.Completed;

  const deadlineTypes = [
    { type: 'VGM', index: 1 },
    { type: 'SI', index: 2 },
    { type: 'UCR', index: 3 },
    { type: 'GateIn', index: null },
  ];

  const [openUpdateLoading, setOpenUpdateLoading] = useState(false);
  const [openDeadlines, setOpenDeadlines] = useState(false);
  const [openInvoiceReportDialogue, setOpenInvoiceReportDialogue] = useState(false);
  const [openProfitLossDialogue, setOpenProfitLossDialogue] = useState(false);
  const [openUpdateBookingDetails, setOpenUpdateBookingDetails] = useState(false);
  const [openSplitBookingDialogue, setOpenSplitBookingDialogue] = useState(false);
  const [openMergeBookingDialogue, setOpenMergeBookingDialogue] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openAuditLogs, setOpenAuditLogs] = useState(false);
  const [openBlHistoryDialogue, setOpenBlHistoryDialogue] = useState(false);
  const [loadingComponent, setLoadingComponent] = useState(false);

  const [actionsCardID, setActionsCardID] = useState('actions');
  const [quotationCardID, setQuotationCardID] = useState('quotation');

  const transportScheduleCreated = booking?.transportSchedule?.length;

  useEffect(() => {
    setLoadingComponent(isLoading);
  }, [isLoading]);

  return (
    <Box m='1.5rem 2.5rem'>
      {/* ALERTS */}
      {!isLoading && !booking && (
        <Box mb={2}>
          <Alert variant='filled' severity='error'>
            <AlertTitle>Error</AlertTitle>
            Booking not found
          </Alert>
        </Box>
      )}
      {booking && id && !isLoading && (
        <>
          {booking.numberOfContainers !==
            booking.transportSchedule?.filter(schedule => schedule.status !== TransportStatus.Cancelled).length &&
            transportScheduleCreated !== 0 && (
              <Box mb={2}>
                <Alert variant='filled' severity='error'>
                  <AlertTitle>Error</AlertTitle>
                  The number of containers does not match the number of containers in the transport schedule - Please
                  edit the transport schedule
                </Alert>
              </Box>
            )}
          {['GateIn', 'SI', 'VGM', 'UCR', 'earliestRelease', 'earliestGateIn'].map(
            (deadline, index) =>
              booking.deadlines[deadline as DeadlineType] !== 'N/A' &&
              (dayjs(booking.etaPOL).diff(dayjs(booking.deadlines[deadline as DeadlineType]), 'days') > 14 ||
                dayjs(booking.etaPOL).diff(dayjs(booking.deadlines[deadline as DeadlineType]), 'days') < 0) && (
                <Box key={index} mb={2}>
                  <Alert variant='filled' severity='error'>
                    <AlertTitle>Error</AlertTitle>
                    {`The ${deadline} deadline can only be within 14 days prior to the ETA POL (not including the ETA POL day)`}
                  </Alert>
                </Box>
              ),
          )}
          {deadlineTypes.map(({ type }) => {
            const deadline = dayjs(booking.deadlines[type as DeadlineType]).format('DD-MM-YYYY');
            const stepsCompleted = booking.stepsCompleted.find(
              step => step.dbRef === type && step.status === StepStatus.Completed,
            );
            if (deadline === currentDate && !stepsCompleted) {
              return <DeadlineAlert key={type} deadlineType={type} />;
            }
            return null;
          })}
          {[0, 4, 5].map(index => {
            const stepsCompleted = booking.stepsCompleted.find(step => step.dbRef === exportSteps[index]?.dbRef);
            return (
              booking.bookingType === 'Export' &&
              stepsCompleted &&
              !uplodedFileTypes?.includes(documentTypes[index]) && (
                <Box key={index} mb={2}>
                  <Alert variant='filled' severity='warning'>
                    {`${documentTypes[index]} document is missing - Please upload!`}
                  </Alert>
                </Box>
              )
            );
          })}
          {booking.bookingType === 'Export' &&
            !booking.stepsCompleted.some(
              step => step.dbRef === exportSteps[5].dbRef && step.status === StepStatus.Completed,
            ) &&
            dayjs(booking.eta).diff(dayjs(), 'days') <= 10 && (
              <Box mb={2}>
                <Alert variant='filled' severity='warning'>
                  The ETA at POD is less than 10 days away - Please dispatch the BL
                </Alert>
              </Box>
            )}
          {hasCustomerCreditLimitExceeded && (
            <Box mb={2}>
              <Alert variant='filled' severity='warning'>
                {`The customer has a total outstanding amount of £${customerOutstandingAmount} which exceeds their credit limit of £${customer?.creditLimit} - Please contact the customer and do not release an BLs until the outstanding amount is settled.`}
              </Alert>
            </Box>
          )}
          {booking.actualEtd && dayjs(booking.actualEtd).diff(dayjs(booking.etd), 'days') > 0 && (
            <Box mb={2}>
              <Alert variant='filled' severity='warning'>
                {`The vessel has sailed ${dayjs(booking.actualEtd).diff(dayjs(booking.etd), 'days')} days later than the ETD`}
              </Alert>
            </Box>
          )}
          {/* ALERTS */}

          <BookingSpeedDial
            bookingId={id}
            bookingNumber={booking.bookingNumber}
            bookingStatus={booking.bookingStatus}
            stepsCompleted={booking.stepsCompleted}
            refetch={refetch}
            showSplitBooking={booking.numberOfContainers > 1 && !!booking.transportSchedule?.length}
            openSplitBookingDialogue={() => setDialogueState(true, setOpenSplitBookingDialogue)}
            openMergeBookingDialogue={() => setDialogueState(true, setOpenMergeBookingDialogue)}
          />
          <Box
            display='grid'
            gridTemplateColumns={{ md: '1 fr 1fr', xl: '2fr 1fr' }}
            mt='1.5rem'
            gap={'2rem'}
            sx={{
              '& > div': {
                borderRadius: '1.9rem',
                padding: '2rem',
              },
            }}
          >
            <Box
              id='booking-summary-container'
              sx={{
                bgcolor: theme.palette.secondary[200],
                gridColumn: { lg: '1 / 3', xl: '1' },
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} gap={'0.5rem'}>
                  <ChipTitle title={`Booking Details - ${booking.bookingNumber}`} />
                  <ChipTitle title={`${booking.bookingTeam}`} />
                  <ChipTitle title={`${booking.bookingStatus}`} />
                </Box>
                <Box display={'flex'}>
                  <Box>
                    <SplitBookingDialogue
                      handleClose={() => setDialogueState(false, setOpenSplitBookingDialogue, refetch)}
                      open={openSplitBookingDialogue}
                      booking={booking}
                    />
                    <MergeBookingDialogue
                      handleClose={() => setDialogueState(false, setOpenMergeBookingDialogue, refetch)}
                      open={openMergeBookingDialogue}
                      booking={booking}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'}>
                    <Box
                      sx={{
                        padding: '0 0.5rem',
                        borderRight: `1px solid ${theme.palette.secondary[300]}`,
                        marginRight: '0.5rem',
                      }}
                    >
                      <Tooltip title='Audit Logs'>
                        <IconButton onClick={() => setOpenAuditLogs(true)}>
                          <RecentActorsOutlinedIcon
                            sx={{
                              fontSize: '1.5rem',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Profit / Loss Report'>
                        <IconButton onClick={() => setOpenProfitLossDialogue(true)}>
                          <BarChartOutlinedIcon
                            sx={{
                              fontSize: '1.5rem',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Invoicing Report'>
                        <IconButton onClick={() => setOpenInvoiceReportDialogue(true)}>
                          <SummarizeOutlinedIcon
                            sx={{
                              fontSize: '1.5rem',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box>
                      <Tooltip title='Refresh'>
                        <IconButton onClick={() => refetch()}>
                          <RefreshOutlined
                            fontSize='large'
                            sx={{
                              color: theme.palette.secondary[500],
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      {!bookingCompleted && (
                        <Tooltip title='Edit Booking Details'>
                          <IconButton onClick={() => setDialogueState(true, setOpenUpdateBookingDetails)}>
                            <EditOutlinedIcon
                              fontSize='large'
                              sx={{
                                color: theme.palette.secondary[500],
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                    <UpdateBookingDetails
                      handleClose={() => setDialogueState(false, setOpenUpdateBookingDetails, refetch)}
                      open={openUpdateBookingDetails}
                      id={id}
                      booking={booking}
                    />
                    <InvoiceReportDialogue
                      handleClose={() => setOpenInvoiceReportDialogue(false)}
                      open={openInvoiceReportDialogue}
                      booking={booking}
                      currentBookingIncome={currentBookingIncome}
                    />
                    <ProfitLossDialogue
                      handleClose={() => setOpenProfitLossDialogue(false)}
                      open={openProfitLossDialogue}
                      booking={booking}
                      currentBookingIncome={currentBookingIncome}
                      currentBookingLoading={bookingIncomeLoading}
                    />
                    <AuditLogsDialogue
                      handleClose={() => setOpenAuditLogs(false)}
                      open={openAuditLogs}
                      auditLogs={bookingsAuditLogs}
                    />
                  </Box>
                </Box>
              </Box>
              <Box display={'grid'} gridTemplateColumns={'repeat(2, 1fr)'} gap={'2rem'}>
                <Box>
                  <BookingDetails booking={booking} />
                </Box>
                <Box>
                  <Box
                    bgcolor={theme.palette.secondary[300]}
                    borderRadius={'13px'}
                    padding={'1rem'}
                    height={'92%'}
                    display='flex'
                    flexDirection='column'
                  >
                    <Box flexGrow={1} overflow='auto'>
                      {quotationCardID === 'quotation' && <QuotationTable booking={booking} refetch={refetch} />}
                      {quotationCardID === 'additionalCharges' && (
                        <AdditionalChargesTable
                          id={id}
                          additionalBuyRates={booking.additionalBuyRates}
                          additionalSellRates={booking.additionalSellRates}
                          refetch={refetch}
                        />
                      )}
                      {invoiceTypes[quotationCardID] && (
                        <InvoiceTable booking={booking} invoiceType={quotationCardID} refetch={invoicesRefetch} />
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <Tabs
                      value={quotationCardID}
                      onChange={(_, newValue) => setQuotationCardID(newValue)}
                      indicatorColor='secondary'
                      sx={{
                        '& .MuiTabs-indicator': {
                          backgroundColor: theme.palette.secondary[300],
                        },
                        '& .MuiTab-root': {
                          minWidth: '75px',
                        },
                      }}
                    >
                      <Tab value='quotation' label='Q' />
                      <Tab value='additionalCharges' label='AC' />
                      <Tab value='salesInvoices' label='SI' />
                      <Tab value='creditNotes' label='CN' />
                      <Tab value='purchaseInvoices' label='PI' />
                      <Tab value='debitNotes' label='DN' />
                    </Tabs>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              id='booking-actions-container'
              sx={{
                bgcolor: theme.palette.secondary[400],
              }}
            >
              <Box display={'flex'} flexDirection={'column'} height={'100%'}>
                {actionsCardID === 'actions' && (
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <ChipTitle title='Actions' />
                    <Box />
                    <VerticalStepper
                      booking={booking}
                      hasCustomerCreditLimitExceeded={hasCustomerCreditLimitExceeded}
                      refetch={refetch}
                    />
                  </Box>
                )}
                {actionsCardID === 'blHistory' && (
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <ChipTitle title='BL History' />
                      <Box>
                        <Tooltip title='Edit BL History'>
                          <IconButton onClick={() => setDialogueState(true, setOpenBlHistoryDialogue)}>
                            <EditOutlinedIcon
                              fontSize='large'
                              sx={{
                                color: theme.palette.secondary[500],
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <UpdateBLHistoryDialogue
                          handleClose={() => setDialogueState(false, setOpenBlHistoryDialogue, refetch)}
                          open={openBlHistoryDialogue}
                          booking={booking}
                        />
                      </Box>
                    </Box>
                    <BLHistoryTable blHistoryArray={booking.blStatusHistory} blRemarks={booking.blRemarks} />
                  </Box>
                )}
                {actionsCardID === 'documents' && (
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <ChipTitle title='Documents' />
                      <Box>
                        <Tooltip title='Upload Documents'>
                          <IconButton onClick={() => setDialogueState(true, setOpenUpload)}>
                            <FileUploadOutlinedIcon
                              fontSize='large'
                              sx={{
                                color: theme.palette.secondary[500],
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <MultipleFileUploadComponent
                          booking={booking}
                          handleClose={() => setDialogueState(false, setOpenUpload, filesRefetch)}
                          open={openUpload}
                          setIsLoading={setLoadingComponent}
                          invoiceRefetch={invoicesRefetch}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <DocumentsTable
                        files={files ?? []}
                        booking={booking}
                        bookingRefetch={refetch}
                        filesRefetch={filesRefetch}
                      />
                    </Box>
                  </Box>
                )}
                <Box>
                  <Tabs
                    value={actionsCardID}
                    onChange={(_, newValue) => setActionsCardID(newValue)}
                    indicatorColor='secondary'
                    sx={{
                      '& .MuiTabs-indicator': {
                        backgroundColor: theme.palette.secondary[300],
                      },
                    }}
                  >
                    <Tab value='actions' label='Actions' />
                    <Tab value='blHistory' label='BL History' />
                    <Tab value='documents' label='Documents' />
                  </Tabs>
                </Box>
              </Box>
            </Box>
            <Box
              id='booking-transport-schedule-container'
              sx={{
                bgcolor: theme.palette.secondary[200],
                gridRowStart: { lg: '3', xl: '2' },
                gridColumn: { lg: '1 / 3', xl: '1' },
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <ChipTitle title={`${booking.bookingType === 'Export' ? 'Loading' : 'Delivery'} Details`} />
                <Box>
                  {!bookingCompleted && (
                    <Tooltip title={`Edit ${booking.bookingType === 'Export' ? 'Loading' : 'Delivery'} Schedule`}>
                      <IconButton onClick={() => setDialogueState(true, setOpenUpdateLoading)}>
                        <EditOutlinedIcon
                          fontSize='large'
                          sx={{
                            color: theme.palette.secondary[500],
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  <UpdateTransportScheduleDialogue
                    handleClose={() => setDialogueState(false, setOpenUpdateLoading, refetch)}
                    open={openUpdateLoading}
                    booking={booking}
                  />
                </Box>
              </Box>

              <Box>
                {booking.transportSites?.map((transportSite, index) => (
                  <Box key={index} m='0.5rem'>
                    <Typography
                      variant='h6'
                      fontWeight={'400'}
                      sx={{
                        color: theme.palette.secondary[500],
                        fontWeight: '600',
                      }}
                    >
                      {`${
                        booking.loadingTypeExport === 'SD'
                          ? booking.bookingType === 'Export'
                            ? 'Loading'
                            : 'Delivery'
                          : 'Pickup Depot'
                      } Point ${index + 1} :`}
                    </Typography>
                    {!transportSite || !transportSite.site
                      ? `Site information is missing`
                      : `${transportSite.site.siteName}, ${transportSite.site.address}, ${transportSite.site.city}, ${transportSite.site.postcode} : ${transportSite.containers} CNs`}
                  </Box>
                ))}

                <Box bgcolor={theme.palette.secondary[300]} borderRadius={'13px'} padding={'1rem'}>
                  <Box>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography
                        variant='h6'
                        sx={{
                          color: theme.palette.secondary[600],

                          letterSpacing: '0.05rem',
                        }}
                      >
                        {transportScheduleCreated
                          ? `${booking.bookingType === 'Export' ? 'Loading' : 'Delivery'} Schedule`
                          : 'No Schedule Available'}
                      </Typography>
                    </Box>
                    {!!transportScheduleCreated && (
                      <TransportScheduleTable
                        transportScheduleArray={booking.transportSchedule ?? []}
                        loadingTypeExport={booking.loadingTypeExport}
                      />
                    )}
                  </Box>
                </Box>
                <Divider sx={{ mt: '1rem' }}>Depot Points</Divider>
                {booking.pickupDepot?.map((depot, index) => (
                  <Box key={index} m='0.5rem'>
                    <Typography
                      variant='h6'
                      fontWeight={'400'}
                      sx={{
                        color: theme.palette.secondary[500],
                        fontWeight: '600',
                      }}
                    >
                      {`Pickup Depot Point ${index + 1} :`}
                    </Typography>
                    {!depot || !depot.site
                      ? `Site information is missing`
                      : `${depot.site.siteName}, ${depot.site.address}, ${depot.site.city}, ${depot.site.postcode} : ${
                          depot.containers
                        } CNs -> Reference: ${depot.reference ?? 'N/A'}`}
                  </Box>
                ))}
                {booking.dropoffDepot?.map((depot, index) => (
                  <Box key={index} m='0.5rem'>
                    <Typography
                      variant='h6'
                      fontWeight={'400'}
                      sx={{
                        color: theme.palette.secondary[500],
                        fontWeight: '600',
                      }}
                    >
                      {`Dropoff Depot Point ${index + 1} :`}
                    </Typography>
                    {!depot || !depot.site
                      ? `Site information is missing`
                      : `${depot.site.siteName}, ${depot.site.address}, ${depot.site.city}, ${depot.site.postcode} : ${
                          depot.containers
                        } CNs -> Reference: ${depot.reference ?? 'N/A'}`}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              id='booking-deadlines-container'
              sx={{
                bgcolor: theme.palette.secondary[200],
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <ChipTitle title={booking.bookingType === 'Export' ? 'Deadlines' : 'Calendar'} />
                {booking.bookingType === 'Export' && (
                  <Box>
                    {!bookingCompleted && (
                      <Tooltip title='Edit Deadlines'>
                        <IconButton onClick={() => setDialogueState(true, setOpenDeadlines)}>
                          <EditOutlinedIcon
                            fontSize='large'
                            sx={{
                              color: theme.palette.secondary[500],
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}

                    <DeadlinesDialogue
                      handleClose={() => setDialogueState(false, setOpenDeadlines, refetch)}
                      open={openDeadlines}
                      id={id}
                      etaPOL={booking.etaPOL}
                      deadlines={booking.deadlines}
                    />
                  </Box>
                )}
              </Box>
              <DeadlineCalendar
                deadlineData={{
                  ...booking.deadlines,
                  etaPOL: booking.etaPOL,
                  etd: booking.etd,
                  eta: booking.eta,
                }}
              />
              <Divider />
              <Box display={'grid'} gridTemplateColumns={'repeat(4,1fr)'} gap={'1.5rem'} margin={'1rem 1.5rem'}>
                {['GateIn', 'SI', 'VGM', 'UCR'].map(
                  (deadline, index) =>
                    booking.deadlines[deadline as DeadlineType] !== 'N/A' && (
                      <Box key={index}>
                        <Typography fontSize={'1rem'} color={theme.palette.secondary[400]} fontWeight={'500'}>
                          {deadline}
                        </Typography>
                        <Typography fontSize={'0.8rem'}>
                          {dayjs(booking.deadlines[deadline as DeadlineType]).format('DD-MM-YY')}
                        </Typography>
                        <Typography fontSize={'0.8rem'}>
                          {extractDeadlineTime(booking.deadlines[deadline as DeadlineType])}
                        </Typography>
                      </Box>
                    ),
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
      {loadingComponent && (
        <Box
          position='fixed'
          top={0}
          left={0}
          right={0}
          bottom={0}
          display='flex'
          alignItems='center'
          justifyContent='center'
          bgcolor={'rgba(0,0,0,0.5)'}
          zIndex={9999}
        >
          <CircularProgress size={'10rem'} />
        </Box>
      )}
    </Box>
  );
};

export default Booking;
