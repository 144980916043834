import React from 'react';
import { useState } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div className='w-full dark:bg-black dark:bg-dot-white/[0.2] bg-dot-black/[0.8] relative flex items-center justify-center'>
      <div className='absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black  [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
      <Box display={'flex'} width='100%' height='100%'>
        <Sidebar drawerWidth='14rem' isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
        <Box flexGrow={1}>
          <Navbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
          <Outlet />
        </Box>
      </Box>
    </div>
  );
};

export default Layout;
