import dayjs from 'dayjs';
import { Booking, TeamType, TransportSchedule, InvoiceCharge } from 'models/booking.model';
import { Site, Customer, Port, Vendor } from 'models/index.model';
import { exportBookingTeams, importBookingTeams } from 'dataAssets/constants';
import { DEFAULT_BOOKING } from 'dataAssets/constants';
import { useSelector } from 'react-redux';
import { sec } from 'auth/accessToken';
import axios from 'axios';
import { useEffect, useRef } from 'react';
import { AlertColor } from '@mui/material/Alert';

const EMPTY_VALUE_LABEL = { value: '', label: '' };

export interface SnackbarState {
  open: boolean;
  color: AlertColor;
  message: string;
}

export const defaultSnackbarState: SnackbarState = {
  open: false,
  color: 'success',
  message: '',
};

export function getValueAndLabelFromPort(port?: Port) {
  return port
    ? {
        value: `${port._id}`,
        label: `${port.portName}, ${port.country}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromVendor(vendor?: Vendor) {
  return vendor
    ? {
        value: `${vendor._id}`,
        label: `${vendor.name}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromSite(site?: Site) {
  return site
    ? {
        value: site._id,
        label: `${site.siteName}, ${site.address}, ${site.city}, ${site.postcode}, ${site.country}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromCustomer(customer?: Customer) {
  return customer
    ? {
        value: `${customer._id}`,
        label: `${customer.name}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function handleSnackbarClose(reason: string, setStateFunc: React.Dispatch<React.SetStateAction<boolean>>) {
  if (reason === 'clickaway') {
    return;
  }

  setStateFunc(false);
}

export function getLabelForBookingDetail(value: any, bookingDetail: keyof Booking) {
  switch (bookingDetail) {
    case 'consignor':
    case 'consignee':
    case 'carrier':
      return value?.name;
    case 'agent':
      return value?.name;
    case 'portOfLoading':
    case 'portOfDestination':
    case 'placeOfDelivery':
      return `${value?.portName ?? 'N/A'}, ${value?.country ?? 'N/A'}`;
    case 'cargoValue':
      return `${value?.currency} ${value?.value}`;
    case 'vesselVoyage':
      return `${value?.vesselName}, ${value?.voyageNumber} [${value?.vesselFlag}]`;
    case 'etaPOL':
    case 'etd':
    case 'eta':
      return dayjs(value).format('DD/MM/YYYY');
    default:
      return value;
  }
}

export const useUserPermissions = () => {
  return useSelector((state: any) => state.global.permissions);
};

export const formatDeadline = (deadline: string) => {
  return deadline !== 'N/A' ? dayjs(deadline).format('DD/MM/YYYY') : 'TBA';
};

export const setBookingTeams = (hasExportPermission: boolean, hasImportPermission: boolean) => {
  if (hasExportPermission && hasImportPermission) {
    return Array.from(new Set(exportBookingTeams.concat(importBookingTeams))) as TeamType[];
  } else if (hasExportPermission) {
    return exportBookingTeams;
  } else if (hasImportPermission) {
    return importBookingTeams;
  }
};

export const containerNumbersList = (transportSchedule: TransportSchedule[] | undefined) => {
  return transportSchedule
    ? transportSchedule.map((schedule: TransportSchedule) => schedule.containerNumber).join('/ ')
    : '';
};

export const totalInvoiceValue = (charges: InvoiceCharge[], numberOfContainer: number) => {
  const total = charges?.reduce(
    (acc, charge) => acc + charge.rate * charge.exchangeRate * (charge.base === 'CN' ? numberOfContainer : 1),
    0,
  );
  return total?.toFixed(2);
};

export const setDefaultBookingValues = (hasExportPermission: boolean, hasImportPermission: boolean): Booking => {
  return {
    ...DEFAULT_BOOKING,
    bookingType: hasExportPermission ? 'Export' : hasImportPermission ? 'Import' : 'Export',
    bookingTeam: hasExportPermission ? TeamType.Rockers : hasImportPermission ? TeamType.Falcons : TeamType.Rockers,
  };
};

export const checkMissingKeys = (booking: Booking) => {
  const missingKeys: string[] = [];

  ['consignor', 'consignee', 'carrierBookingNumber'].forEach(key => {
    if (!booking[key as keyof Booking]) {
      missingKeys.push(key);
    }
  });

  if (!booking.transportSchedule?.length) {
    missingKeys.push('Transport Schedule');
  }

  // Check if any schedule within transportSchedule is missing containerNumber
  const isContainerMissing = booking.transportSchedule?.some(schedule => !schedule.containerNumber);
  if (isContainerMissing) {
    missingKeys.push('Container Numbers');
  }

  return missingKeys;
};

export const splitTransportScheduleByHauler = (schedule?: TransportSchedule[]): Record<string, TransportSchedule[]> => {
  const haulerGroups: Record<string, TransportSchedule[]> = {};

  schedule?.forEach(item => {
    const hauler = item.hauler;

    if (!haulerGroups[hauler]) {
      haulerGroups[hauler] = [];
    }

    haulerGroups[hauler].push(item);
  });

  return haulerGroups;
};

export const uniqueNameRefinement = (items: any, itemToUpdate: any, key: string) => (name: any) => {
  const lowercaseName = name.trim().toLowerCase().replace(/\s+/g, ' ');
  return !items?.find(
    (item: any) =>
      item[key]?.trim().toLowerCase().replace(/\s+/g, ' ') === lowercaseName && item._id !== itemToUpdate?._id,
  );
};

export const setDialogueState = (
  open: boolean,
  setStateFunc: React.Dispatch<React.SetStateAction<boolean>>,
  refetchFunc?: () => void,
) => {
  setStateFunc(open);
  if (refetchFunc) {
    refetchFunc();
  }
};

export const extractDeadlineTime = (deadline: string | undefined) => {
  return deadline ? dayjs(deadline).format('HH:mm') : 'N/A';
};

export const get = (obj: Record<string, any>, path: string, defaultValue: unknown = undefined) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const generateToken = async (id: string, code?: string) => {
  const details: Record<string, string> = {
    state: id,
  };
  if (typeof code !== 'undefined') {
    details.code = code;
  }
  const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/token/`;
  const accessToken = await sec.getAccessTokenSilently()();
  return axios.post(apiUrl, new URLSearchParams(details), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<typeof callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const extractInvoiceType = (invoiceNumber: string) => {
  const parts = invoiceNumber.split('/');
  return parts[1];
};

export const extractInvoiceShortForm = (invoiceType: string) => {
  switch (invoiceType) {
    case 'salesInvoices':
      return 'SI';
    case 'purchaseInvoices':
      return 'PI';
    case 'creditNotes':
      return 'CN';
    case 'debitNotes':
      return 'DN';
    default:
      return '';
  }
};
