import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import countryList from '../../dataAssets/countryList';
import { Box, TextField, Dialog, DialogContent, DialogTitle, Autocomplete } from '@mui/material';
import { sec } from '../../auth/accessToken';
import { useGetSitesQuery } from 'state/api';
import { BaseDialogueProps, Site } from 'models/index.model';
import AlertSnackbar from 'components/AlertSnackbar';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { uniqueNameRefinement } from 'utils/utils';

export interface SiteDialogueProps extends BaseDialogueProps {
  siteToUpdate?: Site;
}

const CreateSiteDialogue: React.FC<SiteDialogueProps> = ({ handleClose, open, siteToUpdate }) => {
  const isEditing = !!siteToUpdate?._id;

  const { data: sites } = useGetSitesQuery();

  const siteCreationSchema = z.object({
    siteName: z.string().refine(uniqueNameRefinement(sites, siteToUpdate, 'siteName'), {
      message: 'A site with that name already exists',
    }),
    address: z.string().min(1, { message: 'Address is required' }),
    city: z.string().min(1, { message: 'City is required' }),
    postcode: z.string().min(1, { message: 'Postcode is required' }),
    country: z.string().min(1, { message: 'Country is required' }),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<Site>({
    resolver: zodResolver(siteCreationSchema),
  });

  useEffect(() => {
    reset(siteToUpdate);
  }, [siteToUpdate, reset]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const onSubmit = async (data: Site) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/sites/`;

      if (isEditing) {
        apiUrl += `${siteToUpdate?._id}`;
        await axios.patch(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.post(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
      setSnackbarOpen(true);
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='sm'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {isEditing ? 'Edit Site' : 'Create Site'}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box display={'grid'} gridTemplateColumns={'1fr'} gap={'0.5rem'}>
              <TextField
                label='Site Name'
                autoComplete='off'
                variant='filled'
                {...register('siteName')}
                error={!!errors.siteName}
                helperText={errors.siteName?.message}
              />
              <TextField
                label='Address *'
                autoComplete='off'
                variant='filled'
                {...register('address')}
                error={!!errors.address}
                helperText={errors.address?.message}
              />
              <TextField
                label='City *'
                autoComplete='off'
                variant='filled'
                {...register('city')}
                error={!!errors.city}
                helperText={errors.city?.message}
              />
              <TextField
                label='Postcode *'
                autoComplete='off'
                variant='filled'
                {...register('postcode')}
                error={!!errors.postcode}
                helperText={errors.postcode?.message}
              />
              <Controller
                name='country'
                control={control}
                defaultValue='United Kingdom'
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    options={countryList}
                    value={value}
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.toLowerCase() === value.toLowerCase();
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Country *'
                        autoComplete='off'
                        variant='filled'
                        error={!!errors.country}
                        helperText={errors.country?.message}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText={isEditing ? 'Edit' : 'Create'}
        />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={'success'}
        message={isEditing ? 'Site Updated' : 'Site Created'}
      />
    </Box>
  );
};

export default CreateSiteDialogue;
