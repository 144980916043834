import React from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ChevronRightOutlined,
  Groups2Outlined,
  BookOutlined,
  CalendarMonthOutlined,
  LocalShippingOutlined,
  DepartureBoardOutlined,
  WarehouseOutlined,
  ReceiptOutlined,
  ShieldOutlined,
  WaterOutlined,
  ContactsOutlined,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @ts-expect-error: TODO: Fix typing issue
import logo from '../../assets/reudan_cropped.png';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserPermissions } from 'utils/utils';

const navItems = [
  {
    text: 'Bookings',
    icon: <BookOutlined />,
  },
  {
    text: 'Deadlines',
    icon: <CalendarMonthOutlined />,
  },
  {
    text: 'Loadings',
    icon: <DepartureBoardOutlined />,
  },
  {
    text: 'Invoices',
    icon: <ReceiptOutlined />,
  },
  {
    text: 'Customers',
    icon: <Groups2Outlined />,
  },
  {
    text: 'Contacts',
    icon: <ContactsOutlined />,
  },
  {
    text: 'Vendors',
    icon: <LocalShippingOutlined />,
  },
  {
    text: 'Sites',
    icon: <WarehouseOutlined />,
  },
  {
    text: 'Ports',
    icon: <WaterOutlined />,
  },
  {
    text: 'Oauth',
    icon: <ShieldOutlined />,
    permission: 'read:access_token',
  },
];

export interface SidebarProps {
  drawerWidth: string;
  isSidebarOpen: boolean;
  setIsSidebarOpen: any;
}

const Sidebar: React.FC<SidebarProps> = ({ drawerWidth, isSidebarOpen, setIsSidebarOpen }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  const { isAuthenticated } = useAuth0();
  const userPermissions = useUserPermissions();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  return (
    <Box component='nav'>
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant='persistent'
          anchor='left'
          sx={{
            width: drawerWidth,
            '& .MuiDrawer-paper': {
              color: theme.palette.secondary[100],
              backgroundColor: theme.palette.secondary[600],
              boxSizing: 'border-box',
              width: drawerWidth,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box width='100%'>
            <Box m='1.5rem'>
              <Box ml={'0.5rem'}>
                <img
                  src={logo}
                  alt='Logo'
                  width={'70%'}
                  onClick={() => navigate('/homepage')}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
            {isAuthenticated && (
              <List>
                {navItems.map(({ text, icon, permission }) => {
                  if (permission && !userPermissions?.includes(permission)) {
                    return null;
                  }
                  if (!icon) {
                    return (
                      <Typography variant='h6' fontWeight='bold' key={text} sx={{ m: '1rem 2rem' }}>
                        {text}
                      </Typography>
                    );
                  }
                  const lcText = text.toLowerCase().replace(' ', '');

                  return (
                    <ListItem key={text}>
                      <ListItemButton
                        onClick={() => {
                          navigate(`/${lcText}`);
                          setActive(lcText);
                        }}
                        sx={{
                          backgroundColor: active === lcText ? theme.palette.secondary[200] : 'transparent',
                          color: active === lcText ? theme.palette.primary[600] : theme.palette.secondary[100],
                          borderRadius: '13px',
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[100],
                            color: theme.palette.primary[600],
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            color: active === lcText ? theme.palette.primary[600] : theme.palette.secondary[200],
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {active === lcText && <ChevronRightOutlined sx={{ ml: 'auto' }} />}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Box>

          <Box p='1rem'>
            <Typography
              variant='body2'
              sx={{
                color: theme.palette.secondary[200],
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.primary[100],
                },
              }}
              onClick={() => navigate('/privacy-policy')}
            >
              Privacy Policy
            </Typography>
            <Typography
              variant='body2'
              sx={{
                color: theme.palette.secondary[200],
                textAlign: 'center',
                cursor: 'pointer',
                mt: '0.5rem',
                '&:hover': {
                  color: theme.palette.primary[100],
                },
              }}
              onClick={() => navigate('/terms-and-conditions')}
            >
              Terms & Conditions
            </Typography>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
