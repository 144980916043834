import React from 'react';
import { AppState, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { Button, useTheme } from '@mui/material';

const LoginButton = () => {
  const theme = useTheme();
  const { loginWithRedirect } = useAuth0();
  return (
    <Button
      variant='contained'
      onClick={() =>
        loginWithRedirect({
          returnTo: window.location.origin,
        } as RedirectLoginOptions<AppState>)
      }
      sx={{
        bgcolor: theme.palette.secondary[500],
        '&:hover': {
          backgroundColor: theme.palette.secondary[600],
        },
      }}
    >
      Log In
    </Button>
  );
};

export default LoginButton;
