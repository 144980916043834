import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm, useFieldArray, Controller, useWatch, UseFormReturn } from 'react-hook-form';
import {
  Box,
  Button,
  useTheme,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Autocomplete,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { sec } from '../../auth/accessToken';
import { BaseDialogueProps, Customer, Vendor } from 'models/index.model';
import { InvoiceCharge, Invoice, Booking } from 'models/booking.model';
import { bases, currencies, invoiceTypes } from 'dataAssets/constants';
import { generateMenuItemsFromArray } from '../../utils/generateMenuItemsFromArray';
import { getValueAndLabelFromVendor, getValueAndLabelFromCustomer, totalInvoiceValue } from '../../utils/utils';
import AlertSnackbar from 'components/AlertSnackbar';
import { useGetAuditsQuery, useGetCustomersQuery, useGetVendorsQuery } from 'state/api';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { ExRateIsolatedReRender } from 'utils/renderExRate';
import { BaseIsolatedReRender } from 'utils/renderBase';
import ChargeNameAutocomplete from 'components/ChargeNameAutocomplete';

export interface UpdateSalesInvoiceDialogueProps extends BaseDialogueProps {
  invoice: Invoice;
  invoiceType: string;
}

const UpdateInvoiceDialogue: React.FC<UpdateSalesInvoiceDialogueProps> = ({
  handleClose,
  open,
  invoice,
  invoiceType,
}) => {
  const theme = useTheme();

  const [sendToVendor, setSendToVendor] = useState(false);
  const [sendToAnotherCustomer, setSendToAnotherCustomer] = useState(false);
  const [underDispute, setUnderDispute] = useState(invoice.disputeDetails?.underDispute ?? false);

  useEffect(() => {
    setSendToVendor(!!invoice.vendor?._id);
    setSendToAnotherCustomer(!!invoice.customer?._id);
    setUnderDispute(invoice.disputeDetails?.underDispute ?? false);
  }, [invoice.vendor?._id, invoice.customer?._id, invoice.disputeDetails]);

  const { data: vendors } = useGetVendorsQuery();
  const { data: customers } = useGetCustomersQuery();
  const { refetch: refetchAuditLogs } = useGetAuditsQuery(['Invoice', invoice._id]);

  const currentForm = useForm<Invoice>();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = currentForm;

  const handleVendorChange = (value: { label: string; value: string } | null) => {
    if (!value) return;
    const vendor = vendors?.find(item => item._id === value?.value);
    if (vendor) {
      setValue('vendor' as keyof Invoice, vendor);
    }
  };

  const handleCustomerChange = (value: { label: string; value: string } | null) => {
    if (!value) return;
    const customer = customers?.find(item => item._id === value?.value);
    if (customer) {
      setValue('customer' as keyof Invoice, customer);
    }
  };

  useEffect(() => {
    if (invoice) {
      setValue('charges', invoice.charges);
      setValue('remarks', invoice.remarks);
      setValue('invoiceCurrency', invoice.invoiceCurrency);
      setValue('vendorInvoiceNumber', invoice.vendorInvoiceNumber);
      setValue('vendor', invoice.vendor);
      setValue('vendorInvoiceDate', dayjs(invoice.vendorInvoiceDate).format());
      setValue('customer', invoice.customer);
    }
  }, [invoice, setValue]);

  const {
    fields: chargeFields,
    append: chargeAppend,
    remove: chargeRemove,
  } = useFieldArray({
    control,
    name: 'charges',
  });

  useEffect(() => {
    reset(invoice);
  }, [invoice, reset]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const onClose = async () => {
    handleClose();
    reset();
  };

  const onSubmit = async (data: Invoice) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/${invoiceType}/${invoice._id}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      handleClose();
      setSnackbarOpen(true);
      refetchAuditLogs();
    } catch (error) {
      console.error(error);
    }
  };

  const [totalValue, setTotalValue] = useState(0);

  const watchedRates = useWatch({
    control,
    name: 'charges',
  });

  const watchedNumberOfContainers = useWatch({
    control,
    name: 'numberOfContainers',
  });

  useEffect(() => {
    const total = totalInvoiceValue(watchedRates, watchedNumberOfContainers);
    setTotalValue(parseFloat(total));
  }, [watchedRates, watchedNumberOfContainers]);

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='xl'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {`Update ${invoiceTypes[invoiceType]}: ${invoice.approvedInvoiceNumber ?? invoice.performaInvoiceNumber}`}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={'0.5px solid rgba(255, 255, 255, 0.12)'}
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant='h5'>Charges</Typography>
                <Button
                  variant='contained'
                  type='button'
                  onClick={() => chargeAppend({} as InvoiceCharge)}
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box m={'1rem 0 0 0'}>
                {chargeFields.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'35fr 35fr 8fr 10fr 8fr 8fr 3fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id}
                    >
                      <ChargeNameAutocomplete
                        control={control}
                        name={`charges.${index}.chargeName`}
                        chargeType={
                          invoiceType === 'salesInvoices' || invoiceType === 'debitNotes' ? 'Sales' : 'Purchase'
                        }
                      />
                      <TextField
                        label='Additional Details'
                        autoComplete='off'
                        {...register(`charges.${index}.additionalDetails`)}
                      />
                      <Controller
                        name={`charges.${index}.currency`}
                        control={control}
                        defaultValue={currencies[0]}
                        render={({ field }) => (
                          <TextField
                            select
                            autoComplete='off'
                            label='Currency *'
                            defaultValue={currencies[0]}
                            {...field}
                          >
                            {generateMenuItemsFromArray(currencies)}
                          </TextField>
                        )}
                      />
                      <ExRateIsolatedReRender index={index} form={currentForm} defaultCurrency={currencies[0]} />
                      <Controller
                        name={`charges.${index}.rate`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <TextField autoComplete='off' type='number' label='Value *' {...field} />
                        )}
                      />
                      <BaseIsolatedReRender
                        form={currentForm as UseFormReturn<Invoice | Booking, any, Invoice | Booking>}
                        index={index}
                        bases={bases}
                        formPath='charges'
                      />
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => chargeRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
                <Divider sx={{ marginTop: '1rem' }} />
                <Box mt={'1rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <TextField
                    autoComplete='off'
                    size='small'
                    type='number'
                    label='Containers *'
                    {...register('numberOfContainers', {
                      required: 'Containers is required',
                    })}
                    error={!!errors.numberOfContainers}
                    helperText={errors.numberOfContainers?.message}
                  />
                  <Typography variant='h6'>Total Invoice Value: {totalValue}</Typography>
                </Box>
              </Box>
            </Box>
            <Box display={'grid'} gridTemplateColumns={'8fr 1fr'} gap={'1rem'}>
              <TextField label='Remarks' autoComplete='off' defaultValue={invoice.remarks} {...register('remarks')} />
              <Controller
                name='invoiceCurrency'
                control={control}
                defaultValue={invoice.invoiceCurrency}
                render={({ field }) => (
                  <TextField select autoComplete='off' label='Currency *' {...field}>
                    {generateMenuItemsFromArray(currencies)}
                  </TextField>
                )}
              />
            </Box>
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'} gap={'1rem'} mt={'1rem'}>
              {(invoiceType === 'purchaseInvoices' || sendToVendor) && invoice.vendor && (
                <Controller
                  control={control}
                  name={'vendor'}
                  render={({ field: { value } }) => (
                    <Autocomplete
                      value={
                        value === undefined
                          ? getValueAndLabelFromVendor(invoice.vendor)
                          : getValueAndLabelFromVendor(value)
                      }
                      onChange={(event, value) => {
                        handleVendorChange(value);
                      }}
                      options={(vendors ?? []).map((vendor: Vendor) => getValueAndLabelFromVendor(vendor))}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={params => <TextField {...params} label='Vendor *' />}
                    />
                  )}
                />
              )}
              {sendToAnotherCustomer && (
                <Controller
                  control={control}
                  name={'customer'}
                  render={({ field: { value } }) => (
                    <Autocomplete
                      value={
                        value === undefined
                          ? getValueAndLabelFromCustomer(invoice.customer)
                          : getValueAndLabelFromCustomer(value)
                      }
                      onChange={(event, value) => {
                        handleCustomerChange(value);
                      }}
                      disablePortal
                      options={(customers ?? []).map((customer: Customer) => getValueAndLabelFromCustomer(customer))}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={params => <TextField {...params} label='Customer *' />}
                    />
                  )}
                />
              )}
              {invoiceType === 'purchaseInvoices' && (
                <>
                  <TextField
                    label='Vendor Invoice No *'
                    autoComplete='off'
                    defaultValue={invoice.vendorInvoiceNumber}
                    {...register('vendorInvoiceNumber', {
                      required: 'Vendor Invoice No is required',
                    })}
                    error={!!errors.vendorInvoiceNumber}
                    helperText={errors.vendorInvoiceNumber?.message}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name={'vendorInvoiceDate'}
                      render={({ field: { onChange, value, ref } }) => (
                        <DatePicker
                          format='DD/MM/YYYY'
                          label={'Vendor Invoice Date *'}
                          value={dayjs(value)}
                          inputRef={ref}
                          onChange={date => onChange(dayjs(date).format())}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </>
              )}
            </Box>
            <Box mt={'1rem'}>
              <Controller
                control={control}
                name={'disputeDetails.underDispute'}
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        onChange={e => {
                          onChange(e.target.checked);
                          setUnderDispute(e.target.checked);
                        }}
                      />
                    }
                    label='Under Dispute'
                  />
                )}
              />
            </Box>
            {underDispute && (
              <Box mt={'1rem'} display={'grid'} gridTemplateColumns={'repeat(3,1fr)'} gap={'1rem'}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    name='disputeDetails.disputeDate'
                    render={({ field: { onChange, value, ref } }) => (
                      <DatePicker
                        label='Dispute Date'
                        format='DD/MM/YYYY'
                        value={value ? dayjs(value) : null}
                        inputRef={ref}
                        onChange={date => onChange(dayjs(date).format())}
                      />
                    )}
                  />
                </LocalizationProvider>
                <TextField label='Dispute Remarks' autoComplete='off' {...register('disputeDetails.disputeRemarks')} />
                <TextField
                  label='Dispute Follow Up'
                  autoComplete='off'
                  {...register('disputeDetails.disputeFollowUp')}
                />
              </Box>
            )}
          </form>
        </DialogContent>
        <GeneralDialogueActions onClick={handleSubmit(onSubmit)} handleClose={onClose} submitText='Update' />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={'success'}
        message={`${invoiceTypes[invoiceType]}: ${
          invoice.approvedInvoiceNumber ?? invoice.performaInvoiceNumber
        } Updated Successfully !`}
      />
    </Box>
  );
};

export default UpdateInvoiceDialogue;
