import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  Box,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Autocomplete,
  Chip,
  Checkbox,
} from '@mui/material';
import { sec } from '../../auth/accessToken';
import { useGetContactsQuery, useGetCustomersQuery } from '../../state/api';
import { BaseDialogueProps, Customer } from 'models/index.model';
import AlertSnackbar from 'components/AlertSnackbar';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { uniqueNameRefinement } from 'utils/utils';
import countryList from 'dataAssets/countryList';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IntenalEmailTable from 'components/InternalEmailTable';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export interface CustomerDialogueProps extends BaseDialogueProps {
  customerToUpdate?: Customer;
}

const CreateCustomerDialogue: React.FC<CustomerDialogueProps> = ({ handleClose, open, customerToUpdate }) => {
  const isEditing = !!customerToUpdate?._id;
  const [inView, setInView] = useState(true);

  const toggleInView = (display: boolean) => {
    setInView(display);
  };

  const { data: customers } = useGetCustomersQuery();
  const { data: contacts } = useGetContactsQuery();

  const customerCreationSchema = z.object({
    name: z
      .string()
      .min(1, { message: 'Name is required' })
      .refine(uniqueNameRefinement(customers, customerToUpdate, 'name'), {
        message: 'A customer with that name already exists',
      }),
    contacts: z
      .object({
        _id: z.string(),
      })
      .passthrough()
      .array(),
    address: z.string().min(1, { message: 'Address is required' }),
    city: z.string().min(1, { message: 'City is required' }),
    postcode: z.string().min(1, { message: 'Postcode is required' }),
    country: z.string().min(1, { message: 'Country is required' }),
    creditPeriod: z.string().min(1, { message: 'Credit Period is required' }),
    creditLimit: z.string().min(1, { message: 'Credit Limit is required' }),
    EORI: z.string(),
    emailConfig: z
      .object({
        name: z.string(),
        from: z.string().array(),
        to: z.string().array(),
        cc: z.string().array(),
      })
      .array()
      .default([]),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm<Customer>({
    resolver: zodResolver(customerCreationSchema),
  });

  useEffect(() => {
    reset(customerToUpdate);
  }, [customerToUpdate, reset]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const onSubmit = async (data: Customer) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/customers/`;

      if (isEditing) {
        apiUrl += `${customerToUpdate._id}`;
        await axios.patch(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.post(apiUrl, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
      setSnackbarOpen(true);
      handleClose();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        fullWidth
        maxWidth='md'
        sx={{ visibility: inView ? 'visible' : 'hidden' }}
      >
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {`${isEditing ? 'Edit' : 'Create New'} Customer`}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={'0.5rem'}>
              <TextField
                label='Name *'
                autoComplete='off'
                variant='filled'
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <Controller
                control={control}
                name='contacts'
                defaultValue={isEditing ? customerToUpdate.contacts : []}
                render={({ field: { value } }) => (
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    disablePortal
                    limitTags={3}
                    value={value}
                    onChange={(_, value) => {
                      // onChange(value || '');
                      setValue('contacts', value);
                    }}
                    getOptionLabel={val => val.name}
                    isOptionEqualToValue={(option, val) => option.name === val.name}
                    options={contacts ?? []}
                    renderOption={(props, option, { selected, index }) => {
                      return (
                        <li key={index} {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      );
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return <Chip key={key} variant='outlined' label={option.name} size='small' {...tagProps} />;
                      })
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant='filled'
                        label='Contacts '
                        placeholder='Contacts'
                        error={!!errors.contacts}
                        helperText={errors.contacts?.message}
                      />
                    )}
                  />
                )}
              />
              <TextField
                label='Address *'
                autoComplete='off'
                variant='filled'
                {...register('address')}
                error={!!errors.address}
                helperText={errors.address?.message}
                sx={{ gridColumn: '1 / 3' }}
              />
              <TextField
                label='City *'
                autoComplete='off'
                variant='filled'
                {...register('city')}
                error={!!errors.city}
                helperText={errors.city?.message}
              />
              <TextField
                label='Postcode *'
                autoComplete='off'
                variant='filled'
                {...register('postcode')}
                error={!!errors.postcode}
                helperText={errors.postcode?.message}
              />
              <Controller
                name='country'
                control={control}
                defaultValue='United Kingdom'
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    disablePortal
                    options={countryList}
                    value={value}
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.toLowerCase() === value.toLowerCase();
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Country *'
                        autoComplete='off'
                        variant='filled'
                        error={!!errors.country}
                        helperText={errors.country?.message}
                      />
                    )}
                  />
                )}
              />
              <TextField label='EORI ' autoComplete='off' variant='filled' {...register('EORI')} />
              <TextField
                label='Credit Period (Days) * '
                autoComplete='off'
                variant='filled'
                type='number'
                {...register('creditPeriod')}
                error={!!errors.creditPeriod}
                helperText={errors.creditPeriod?.message}
              />
              <TextField
                label='Credit Limit * '
                autoComplete='off'
                variant='filled'
                type='number'
                {...register('creditLimit')}
                error={!!errors.creditLimit}
                helperText={errors.creditLimit?.message}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>£</InputAdornment>,
                }}
              />
              <IntenalEmailTable
                contacts={getValues('contacts')}
                defaultValue={getValues('emailConfig')}
                fieldError={errors.emailConfig?.message}
                setValue={value => setValue('emailConfig', value)}
                toggleParent={toggleInView}
                parentOpen={open}
              />
            </Box>
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText={isEditing ? 'Edit' : 'Create'}
        />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={'success'}
        // TODO: this is not working check
        message={isEditing ? 'Customer Updated!' : 'New Customer Created!'}
      />
    </Box>
  );
};

export default CreateCustomerDialogue;
