import * as React from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { Controller, useFormContext } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { Settings } from '@mui/icons-material';

export enum nameType {
  text,
  list,
}

export enum emailFieldNames {
  from = 'from_emails',
  to = 'to_emails',
  cc = 'cc_emails',
}

export interface EmailFormProp {
  fieldConfig: {
    name: emailFieldNames;
    label?: string;
    options: string[];
    freeSolo?: boolean;
    skipPrePopulate?: boolean;
    limit?: number;
  }[];
  nameField: {
    type: nameType;
    values?: string[];
  };
  masterValues?: Record<string, Record<emailFieldNames, string[]>>;
}

const EmailForm: React.FC<EmailFormProp> = ({ fieldConfig, nameField, masterValues }) => {
  const { control, setValue } = useFormContext();

  // Handler to prevent form submission on Enter
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Stack
      gap={2}
      direction='column'
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Controller
        name='name'
        control={control}
        render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
          <FormControl fullWidth={nameField.type === nameType.text}>
            {nameField.type === nameType.text ? (
              <TextField
                value={value ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(event.target.value);
                }}
                onKeyDown={handleKeyDown}
                error={Boolean(error)}
                onBlur={onBlur}
                inputRef={ref}
                label={'Name'}
              />
            ) : (
              <>
                <InputLabel id='selectLabel'>Config</InputLabel>
                <Select
                  sx={{ width: 'auto', minWidth: 150, maxWidth: '100%' }}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Settings />
                    </InputAdornment>
                  }
                  labelId='selectLabel'
                  label='Config'
                  value={value ?? ''}
                  onChange={(event: SelectChangeEvent) => {
                    fieldConfig.forEach(field => {
                      setValue(field.name, masterValues?.[event.target.value]?.[field.name] ?? []);
                    });
                    onChange(event.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {nameField.values?.map((val, idx) => (
                    <MenuItem key={idx} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            <FormHelperText
              sx={{
                color: 'error.main',
              }}
            >
              {Array.isArray(error) ? error.find(v => v?.message)?.message : (error?.message ?? '')}
            </FormHelperText>
          </FormControl>
        )}
      />
      {fieldConfig.map((field, idx) => (
        <Controller
          key={idx}
          name={field.name}
          control={control}
          render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <Autocomplete
                clearIcon={false}
                freeSolo={field.freeSolo ?? false}
                multiple
                filterSelectedOptions
                onChange={(_event: unknown, item: unknown) => onChange(item)}
                {...(field.skipPrePopulate ? {} : { value: value ?? [] })}
                options={field.options}
                renderTags={(value: string[], props) =>
                  value?.map((option, index) => (
                    <Chip
                      sx={{
                        borderColor: Array.isArray(error) && error[index] ? 'error.main' : 'default',
                      }}
                      variant='outlined'
                      label={option}
                      avatar={<Avatar>{option.substring(0, 1)}</Avatar>}
                      {...props({ index })}
                    />
                  ))
                }
                renderOption={(props, option) => {
                  return (
                    <Box component='li' sx={{ '& div': { mr: 2, flexShrink: 0 } }} {...props}>
                      <Avatar>{option.substring(0, 1)}</Avatar>
                      {option}
                    </Box>
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={Boolean(error)}
                    onBlur={onBlur}
                    inputRef={ref}
                    label={field.label}
                    onKeyDown={handleKeyDown}
                  />
                )}
              />

              <FormHelperText
                sx={{
                  color: 'error.main',
                }}
              >
                {Array.isArray(error) ? error.find(v => v?.message)?.message : (error?.message ?? '')}
              </FormHelperText>
            </FormControl>
          )}
        />
      ))}
    </Stack>
  );
};

export default EmailForm;
