import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { User } from '@auth0/auth0-react';
import axios from 'axios';
import { sec } from '../../../auth/accessToken';
import { UserPreference } from 'models/index.model';
import { useForm } from 'react-hook-form';
import { useGetUserPreferenceDetailsQuery } from 'state/api';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../../../components/Header';
import LoadingButton from 'components/LoadingButton';
import { Box, TextField, Autocomplete } from '@mui/material';
import { countries } from '../../../dataAssets/constants';
import { CountryType } from 'models/index.model';

const UserProfile = () => {
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState<User>({});
  const { data: userPref, refetch, isLoading, isSuccess } = useGetUserPreferenceDetailsQuery(currentUser.email ?? '');
  const [country, setCountry] = useState<CountryType | null>(null);

  const useFormReference = useForm<UserPreference>({
    defaultValues: { email: '', firstName: '', lastName: '', nickname: '' },
  });

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useFormReference;

  React.useEffect(() => {
    if (location.state?.user) {
      setCurrentUser(location.state.user);
      setValue('email', location.state.user.email);
    }
    if (userPref) {
      setValue('firstName', userPref.firstName === undefined ? '' : `${userPref.firstName}`);
      setValue('lastName', userPref.lastName === undefined ? '' : `${userPref.lastName}`);
      setValue('nickname', userPref.nickname === undefined ? '' : `${userPref.nickname}`);

      if (userPref.phoneNumber) {
        const phoneNumber = Number(userPref.phoneNumber.toString().slice(userPref.phoneNumber.toString().length - 10));
        const countryCode = userPref.phoneNumber.toString().slice(0, userPref.phoneNumber.toString().length - 10);
        const country = countries.filter(country => country.phone.toString() === countryCode.toString());
        setCountry(country[0]);
        setValue('phoneNumber', phoneNumber);
      }
    }
  }, [location, userPref, countries]);

  const handleCountryChange = (value: CountryType | null) => {
    if (value) {
      setCountry(value);
    }
  };

  const onSubmit = async (data: UserPreference) => {
    const accessToken = await sec.getAccessTokenSilently()();

    const formData = new FormData();

    formData.append('email', data.email);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('nickname', data.nickname);
    formData.append('phoneNumber', `${country?.phone}${data.phoneNumber.toString()}`);

    const formDataObject: { [key: string]: string } = {};

    formData.forEach((value, key) => {
      formDataObject[key] = value as string;
    });

    try {
      if (isSuccess) {
        await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/userProfile`, formDataObject, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/userProfile`, formDataObject, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      refetch();
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box className='p-4'>
      <Header title='User Profile' />
      <Box className='h-full w-full flex flex-col gap-4 place-content-evenly mt-8'>
        <TextField
          autoComplete='off'
          type='string'
          label='First Name'
          {...register('firstName', {
            required: 'First name is required.',
          })}
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
        />
        <TextField
          autoComplete='off'
          type='string'
          label='Last Name'
          {...register('lastName', {
            required: 'Last name is required.',
          })}
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
        />
        <Box className='flex flex-row w-full gap-2'>
          <Autocomplete
            sx={{ width: '20%' }}
            value={country}
            options={countries}
            autoHighlight
            getOptionLabel={option => option.label}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            onChange={(_, value) => {
              handleCountryChange(value);
            }}
            renderOption={(props, option) => {
              const { ...optionProps } = props;
              return (
                <Box key={option.id} component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
                  <img
                    loading='lazy'
                    width='20'
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=''
                  />
                  {option.label} ({option.code}) +{option.phone}
                </Box>
              );
            }}
            renderInput={params => (
              <TextField
                {...params}
                label='Country'
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disable',
                }}
              />
            )}
          />
          <TextField
            sx={{ width: '80%' }}
            autoComplete='off'
            type='number'
            label='Phone Number'
            {...register('phoneNumber', {
              maxLength: { value: 10, message: 'Phone number must not exceed 10 digits.' },
              minLength: { value: 10, message: 'Phone number must be of 10 digits.' },
            })}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
          />
        </Box>
        <TextField
          autoComplete='off'
          type='string'
          label='Nick Name'
          {...register('nickname')}
          error={!!errors.nickname}
          helperText={errors.nickname?.message}
        />
      </Box>
      <Box className='py-4'>
        <LoadingButton
          loadingText='Updating'
          text='Update'
          sx={theme => ({
            color: theme.palette.primary[700],
            bgcolor: theme.palette.secondary[200],
            '&:hover': { bgcolor: theme.palette.secondary[300] },
          })}
          onClick={handleSubmit(onSubmit)}
        />
      </Box>
    </Box>
  );
};

export default UserProfile;
