import * as React from 'react';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import { useGetUserPreferenceDetailsQuery } from 'state/api';
import CircularProgress from '@mui/material/CircularProgress';
import { useDropzone } from 'react-dropzone';
import Typography from '@mui/material/Typography';
import { Clear } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from 'components/LoadingButton';
import { sec } from 'auth/accessToken';
import axios from 'axios';
import { User } from '@auth0/auth0-react';

type PreviewFile = File & {
  preview: string;
};

const Signature = () => {
  const location = useLocation();
  const [currentUser, setCurrentUser] = React.useState<User>({});
  const [uploadFile, setUploadFile] = React.useState<PreviewFile | undefined>();
  const [signature, setSignature] = React.useState<string | undefined>();
  const { data: userPref, refetch, isLoading, isSuccess } = useGetUserPreferenceDetailsQuery(currentUser.email ?? '');
  React.useEffect(() => {
    if (location.state?.user) {
      setCurrentUser(location.state.user);
    }
  }, [location]);
  React.useEffect(() => {
    async function loadSignature() {
      if (
        typeof currentUser?.email === 'undefined' ||
        typeof userPref?.signature === 'undefined' ||
        userPref?.signature === ''
      ) {
        return;
      }
      setSignature(undefined);
      const accessToken = await sec.getAccessTokenSilently()();
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/userPreferences/${currentUser.email}/signature`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      const imageUrl = URL.createObjectURL(response.data);
      setSignature(imageUrl);
    }
    loadSignature();
  }, [userPref]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] },
    multiple: false,
    onDropAccepted(files) {
      setUploadFile(Object.assign(files[0], { preview: URL.createObjectURL(files[0]) }));
    },
    disabled: !!uploadFile,
  });
  const removeFromUpload = () => {
    setUploadFile(undefined);
  };
  const uploadSignature = async () => {
    if (typeof uploadFile === 'undefined' || typeof currentUser.email === 'undefined') {
      return;
    }
    const formData = new FormData();
    formData.append('signature', uploadFile);
    formData.append('email', currentUser.email);
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      if (isSuccess) {
        await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/userPreferences/${currentUser.email}`, formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/userPreferences`, formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      refetch();
      setUploadFile(undefined);
    }
  };
  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <Box className='h-full w-full flex flex-col gap-4 place-content-evenly'>
      {userPref?.signature ? (
        <>
          <Typography variant='h3' className='place-self-center'>
            Signature Preview
          </Typography>
          {!signature ? (
            <CircularProgress className='place-self-center' />
          ) : (
            <Box
              className='flex-none place-self-center'
              component='img'
              sx={{
                height: 200,
                width: 600,
              }}
              alt='Signature image'
              src={signature}
              onLoad={() => {
                if (signature) {
                  URL.revokeObjectURL(signature);
                }
              }}
            />
          )}
        </>
      ) : (
        <Typography className='flex-none'>No Sigature uploaded yet</Typography>
      )}
      <Box
        {...getRootProps({ className: 'dropzone disabled' })}
        className={`grow border border-dashed text-center place-content-center ${uploadFile && 'cursor-not-allowed'}`}
      >
        <input {...getInputProps()} />
        <Typography>Drag 'n' drop some files here, or click to select files</Typography>
      </Box>
      <Box className='flex-none flex flex-col place-items-center gap-2'>
        {uploadFile ? (
          <>
            <Typography>Signature to upload</Typography>
            <Badge
              className='flex-none'
              badgeContent={
                <Tooltip title='Remove image'>
                  <IconButton onClick={removeFromUpload} sx={{ color: 'rgba(255, 255, 255, 0.54)' }}>
                    <Clear />
                  </IconButton>
                </Tooltip>
              }
            >
              <Box
                component='img'
                sx={{
                  height: 200,
                  width: 600,
                }}
                alt='Signature image'
                src={uploadFile.preview}
                onLoad={() => {
                  URL.revokeObjectURL(uploadFile.preview);
                }}
              />
            </Badge>
            <LoadingButton
              loadingText='Uploading'
              text='Upload'
              sx={theme => ({
                color: theme.palette.primary[700],
                bgcolor: theme.palette.secondary[200],
                '&:hover': { bgcolor: theme.palette.secondary[300] },
              })}
              onClick={uploadSignature}
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default Signature;
