const { Box } = require('@mui/material');
const { styled } = require('@mui/system');

// todo: Figure out where this should live, normal component / styled components?
const FlexBetween = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export default FlexBetween;
