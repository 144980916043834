import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, IconButton, CircularProgress, Box, Typography } from '@mui/material';
import { EditOutlined, AddOutlined } from '@mui/icons-material';
import { useUserPermissions } from 'utils/utils';

interface CreateEditAutocompleteProps<T> {
  fetchOptions: (query: string) => Promise<T[]>;
  getOptionLabel: (option: T) => string;
  onEdit: (option: T) => void;
  onCreate: (newLabel: string) => void;
  onChange: (value: T | null) => void;
  value: T | null;
  label: string;
}

const CreateEditAutocomplete = <T,>({
  fetchOptions,
  getOptionLabel,
  onEdit,
  onCreate,
  onChange,
  value,
  label,
}: CreateEditAutocompleteProps<T>) => {
  const userPermissions = useUserPermissions();
  const hasUpdateMasterDataPermission = userPermissions.includes('update:masterdata');

  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchOptions(inputValue);
        setOptions(data);
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchData();
    }
  }, [inputValue, fetchOptions, open]);

  const handleClear = () => {
    onChange(null);
    setInputValue('');
  };

  return (
    <Autocomplete
      value={value}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => getOptionLabel(option) === getOptionLabel(value)} // Custom equality check
      filterSelectedOptions
      loading={loading}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Box display='flex' alignItems='center' width='100%'>
            <Typography flexGrow={1}>{getOptionLabel(option)}</Typography>
            {hasUpdateMasterDataPermission && (
              <IconButton onClick={() => onEdit(option)} size='small'>
                <EditOutlined fontSize='small' />
              </IconButton>
            )}
          </Box>
        </li>
      )}
      onChange={(event, selectedOption) => {
        if (selectedOption === null) {
          handleClear(); // Handle the clear action
        } else if (!selectedOption && inputValue.trim() && !options.some(opt => getOptionLabel(opt) === inputValue)) {
          onCreate(inputValue); // Trigger creation if no matching option is found
        } else {
          onChange(selectedOption);
        }
      }}
      noOptionsText={
        inputValue.trim() && !loading ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            padding='8px'
            style={{ cursor: 'pointer' }}
            onClick={() => onCreate(inputValue)}
          >
            <Typography>Create "{inputValue}"</Typography>
            <AddOutlined />
          </Box>
        ) : (
          'No options'
        )
      }
    />
  );
};

export default CreateEditAutocomplete;
