import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { visuallyHidden } from '@mui/utils';
import { Contact, EmailConfig } from 'models/index.model';
import { Edit } from '@mui/icons-material';
import HorizontalStepper, { CustomStepProp } from 'components/HorizontalStepper/HorizontalStepper';
import EmailForm from 'components/EmailForm';
import { z } from 'zod';
import CustomModel from 'components/CustomModel';
import { useGetContactsQuery, useGetUserPreferenceQuery } from 'state/api';
import TextField from '@mui/material/TextField';
import { emailFieldNames, nameType } from 'components/EmailForm/EmailForm';

type openCustomModelType = {
  open: boolean;
  formElement: CustomStepProp | null;
  idx: number;
};

interface Data {
  id: number;
  name: string;
  from: string;
  to: string;
  cc: string;
}
function getStringFromArr(arr: string[], trimLen = 3) {
  const length = arr.length;
  // Check if array length is less than or equal to 3
  if (length <= trimLen) {
    return arr.join(', ');
  }
  // Join the first 3 elements and add "+<count>"
  return `${arr.slice(0, trimLen).join(', ')} +${length - trimLen}`;
}
function createData(id: number, name: string, from: string[], to: string[], cc: string[]): Data {
  return {
    id,
    name,
    from: getStringFromArr(from, 2),
    to: getStringFromArr(to, 2),
    cc: getStringFromArr(cc, 2),
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'from',
    disablePadding: true,
    label: 'From',
  },
  {
    id: 'to',
    disablePadding: false,
    label: 'To',
  },
  {
    id: 'cc',
    disablePadding: false,
    label: 'cc',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='secondary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell size='small' align='center' padding='none' />
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  setOpenCustomModel: (val: openCustomModelType) => void;
  deleteSelected: () => void;
}
function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, setOpenCustomModel, deleteSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          bgcolor: theme => theme.palette.grey[800],
        },
        numSelected > 0 && {
          bgcolor: theme => alpha(theme.palette.background.default, theme.palette.action.activatedOpacity),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color='inherit' variant='subtitle1' component='div'>
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant='h6' id='tableTitle' component='div'>
          Email Configuration
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title='Delete'>
          <IconButton onClick={deleteSelected}>
            <DeleteIcon fontSize='large' color='error' />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title='Add Email'>
          <IconButton
            onClick={() =>
              setOpenCustomModel({
                open: true,
                formElement: null,
                idx: -1,
              })
            }
          >
            <AddIcon fontSize='large' color='secondary' />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}
export interface IntenalEmailTableProps {
  contacts?: Contact[];
  defaultValue?: EmailConfig[];
  setValue: (config: EmailConfig[]) => void;
  fieldError?: string;
  toggleParent: (display: boolean) => void;
  parentOpen: boolean;
}

const IntenalEmailTable: React.FC<IntenalEmailTableProps> = ({
  defaultValue,
  toggleParent,
  setValue,
  parentOpen,
  contacts = [],
}) => {
  const { data: userPreferenceArr } = useGetUserPreferenceQuery();
  const emailsOfUsers = React.useMemo(() => {
    return userPreferenceArr?.map(pref => pref.email) || [];
  }, [userPreferenceArr]);
  const emailsOfContacts = contacts.map(c => c.email);
  const [openCustomModel, setOpenCustomModel] = React.useState<openCustomModelType>({
    open: false,
    formElement: null,
    idx: -1,
  });

  const setOpenCustomModelFn = (val: openCustomModelType) => {
    setOpenCustomModel(val);
    toggleParent(!val.open);
  };
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('from');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const getEmailFormElement = React.useCallback(
    (idx: number, name?: string, from: string[] = [], to: string[] = [], cc: string[] = []) => {
      const zodEmailSelectorValidation = z.array(z.string().email({ message: 'Invalid email format' }));
      return {
        element: (
          <EmailForm
            nameField={{
              type: nameType.text,
            }}
            fieldConfig={[
              {
                name: emailFieldNames.from,
                label: 'From Emails',
                options: [...new Set([...from, ...emailsOfUsers])],
                skipPrePopulate: idx === -1,
                freeSolo: true,
              },
              {
                name: emailFieldNames.to,
                label: 'To Emails',
                options: [...new Set([...to, ...emailsOfUsers, ...emailsOfContacts])],
                skipPrePopulate: idx === -1,
                freeSolo: true,
              },
              {
                name: emailFieldNames.cc,
                label: 'CC Emails',
                options: [...new Set([...cc, ...emailsOfUsers, ...emailsOfContacts])],
                skipPrePopulate: idx === -1,
                freeSolo: true,
              },
            ]}
          />
        ),
        label: 'Email Confirmation',
        validationSchema: z.object({
          name: z.string().min(1, 'Name is mandatory'),
          from_emails: zodEmailSelectorValidation
            .min(1, {
              message: 'This field has to be filled.',
            })
            .max(1, {
              message: 'This field can have only 1 value currently.',
            }),
          to_emails: zodEmailSelectorValidation.min(1, {
            message: 'This field has to be filled.',
          }),
          cc_emails: zodEmailSelectorValidation,
        }),
        defaultValues: {
          name: name ?? '',
          from_emails: from,
          to_emails: to,
          cc_emails: cc,
        },
      };
    },
    [emailsOfUsers, emailsOfContacts],
  );

  const rows = (defaultValue ?? []).map(({ name, from, to, cc }, idx) => createData(idx, name, from, to, cc));

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () => [...rows].sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const addEmailFn = (idx: number) => async (data?: Record<string, string | string[]>) => {
    const newVal = defaultValue ?? [];
    if (data) {
      const newData = {
        name: typeof data.name === 'string' ? data.name : data.name + '',
        from: Array.isArray(data.from_emails) ? data.from_emails : [data.from_emails],
        to: Array.isArray(data.to_emails) ? data.to_emails : [data.to_emails],
        cc: Array.isArray(data.cc_emails) ? data.cc_emails : [data.cc_emails],
      };
      if (idx === -1) {
        newVal.push(newData);
      } else {
        newVal[idx] = newData;
      }
    }
    setValue(newVal);
    setOpenCustomModelFn({
      open: false,
      formElement: null,
      idx: -1,
    });
  };

  const deleteSelected = () => {
    const newVal = defaultValue ?? [];
    selected.forEach(idx => newVal.splice(idx, 1));
    setValue(newVal);
    setSelected([]);
  };

  return (
    <>
      <Box sx={{ gridColumn: '1 / 3' }}>
        <Paper sx={{ width: '100%', mb: 2, bgcolor: theme => theme.palette.grey[800] }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            setOpenCustomModel={setOpenCustomModelFn}
            deleteSelected={deleteSelected}
          />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'small'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = selected.includes(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row.id)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          color='secondary'
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component='th' id={labelId} scope='row' padding='none'>
                        {row.name}
                      </TableCell>
                      <TableCell>{row.from}</TableCell>
                      <TableCell>{row.to}</TableCell>
                      <TableCell>{row.cc}</TableCell>
                      <TableCell size='small' align='center' padding='none'>
                        <Tooltip title='Edit'>
                          <IconButton
                            onClick={event => {
                              event.stopPropagation();
                              const currentVal = defaultValue?.at(index);
                              setOpenCustomModelFn({
                                open: true,
                                idx: index,
                                formElement: getEmailFormElement(
                                  index,
                                  currentVal?.name,
                                  currentVal?.from,
                                  currentVal?.to,
                                  currentVal?.cc,
                                ),
                              });
                            }}
                          >
                            <Edit fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <CustomModel
        sx={{
          visibility: openCustomModel.open ? 'visible' : 'hidden',
        }}
        open={parentOpen}
        onClose={() =>
          setOpenCustomModelFn({
            open: false,
            formElement: null,
            idx: -1,
          })
        }
        children={
          openCustomModel.open ? (
            <HorizontalStepper
              title={`${openCustomModel.idx === -1 ? 'Add' : 'Edit'} Email`}
              steps={[openCustomModel.formElement ?? getEmailFormElement(-1)]}
              onSubmit={addEmailFn(openCustomModel.idx)}
            />
          ) : (
            <></>
          )
        }
      />
    </>
  );
};
export default IntenalEmailTable;
